import Header from "../../components/Header";
import Footer from "../../components/Footer";
import React from "react";
import Text from "../../components/Text";
import CompactTestimonial from "./components/CompactTestimonial";
import {internFeatured, internTestimonials, ministerTestimonials} from "./config";
import TestimonialCarousel from "./components/TestimonialCarousel";

const Testimonials = () => {
    const featuredMinister = ministerTestimonials[0];

    return (
        <div>
            <Header />
            <div className="bg-white container-fluid overflow-visible">
                <div className="w-full flex flex-wrap items-center py-16 mx-auto max-w-7xl px-4 sm:pt-20 sm:px-6 md:pt-16 lg:py-28 lg:px-8 xl:pt-28">
                    <div className="w-full">
                        <h4 className="text-4xl text-black font-semibold mb-6 font-poppins lg:w-3/4 sm:w-100">
                          <span className="block xl:inline leading-normal">
                            <span className="text-orange">Gateway</span> for Students & Young Professionals to Policy,
                              Politics and Governance
                          </span>
                        </h4>
                        <Text className="mb-4 md:w-3/4 lg:w-1/2 sm:w-100 text-justify">
                            In Just 1 year of our initiation, we have helped over 1,573 students and young professionals
                            enter into the field of policy, politics and governance of India through our various internship
                            initiatives and job network.
                        </Text>
                    </div>

                    <div className="w-full my-36 flex justify-center">
                        <CompactTestimonial className="w-full lg:w-3/4" name={featuredMinister.name} designation={featuredMinister.designation} image={featuredMinister.image}>
                            {featuredMinister.testimonial}
                        </CompactTestimonial>
                    </div>

                    <div className="mb-20">
                        <h4 className="text-3xl text-black font-semibold mb-6 font-poppins lg:w-3/4 sm:w-100 leading-10">
                            Political Leaders Find <span className="text-orange">Value</span> in Our Programmes &
                            <span className="text-orange"> Trust</span> Our Network
                        </h4>
                    </div>

                    <TestimonialCarousel data={ministerTestimonials.slice(1)} />

                    <div className="w-full my-36 flex justify-center">
                        <CompactTestimonial className="w-full lg:w-3/4" name={internFeatured.name} designation={internFeatured.designation} image={internFeatured.image} link={internFeatured.link}>
                            {internFeatured.testimonial}
                        </CompactTestimonial>
                    </div>

                    <div className="mb-20">
                        <h4 className="text-3xl text-black font-semibold mb-6 font-poppins lg:w-3/4 sm:w-100 leading-10">
                            Our Interns Are <span className="text-orange">Excited to Work</span> with the Government
                        </h4>
                    </div>

                    <TestimonialCarousel data={internTestimonials} />

                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Testimonials;
