import React from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import styles from './styles.module.scss';
import Text from "../../../components/Text";
import OtherInitiatives from "../components/OtherInitiatives";
import cn from "classnames";

export const ICPCInternship = () => {
    return (
      <div>
        <Header />
        <div className="bg-white container-fluid">
          <div className="flex flex-wrap items-center w-full px-4 py-16 mx-auto max-w-7xl sm:pt-20 sm:px-6 md:pt-16 lg:py-28 lg:px-8 xl:pt-28">
            <div className="w-full md:w-1/2 sm:w-100">
              <h1 className="mb-6 text-4xl font-semibold text-black font-poppins">
                <span className="text-orange">ICPC</span> Internship Programme
              </h1>
              <p className="mb-4 text-justify text-darkBlue font-opensans">
                The aim of the ICPC Internship program is to introduce students
                from Indian institutions to the basics of policy-making,
                politics and governance.
              </p>
              {/* <p className="italic font-semibold text-black font-poppins">
                Applications have been closed for now. Please check back later.
              </p> */}
              <a
                href="/apply"
                rel="noreferrer"
                className="inline-flex items-center justify-center px-4 py-2 mt-8 text-base font-medium text-white border border-transparent rounded-md shadow-sm whitespace-nowrap font-poppins bg-orange"
              >
                Apply to ICPC Internship Programme
              </a>
            </div>
            <div className="flex justify-end w-full mt-8 md:w-1/2 md:mt-0">
              <img
                src="icpc-person.png"
                alt="Institutional Collaboration with IIDL"
                style={{ borderRadius: 8, width: "100%", maxWidth: 450 }}
              />
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="flex flex-col flex-wrap items-center justify-center w-full px-4 py-16 mx-auto max-w-7xl sm:pt-20 sm:px-6 md:pt-16 lg:py-28 lg:px-8 xl:pt-28">
            <div className="text-center md:w-1/2 sm:w-full">
              <h4 className="mb-6 text-3xl font-semibold font-poppins">
                <span className="text-orange">Benefits</span> of the ICPC
                Internship Programme
              </h4>
            </div>
            <div className="flex flex-col flex-wrap w-full gap-3 my-12 md:flex-row">
              <div className="flex-1 px-10 py-6 overflow-hidden text-center bg-white bg-no-repeat rounded-lg shadow-md ">
                <h4 className="mt-5 text-2xl font-medium font-poppins text-orange">
                  #1
                </h4>
                <p className="mt-3 text-darkBlue font-opensans">
                  <strong>Equip yourself</strong> with the primary skill set to
                  work with politicians and administrators (in and out of
                  government).
                </p>
              </div>
              <div className="flex-1 px-10 py-6 overflow-hidden text-center bg-white bg-center bg-no-repeat rounded-lg shadow-md ">
                <h4 className="mt-5 text-2xl font-medium font-poppins text-orange">
                  #2
                </h4>
                <p className="mt-3 text-darkBlue font-opensans">
                  <strong>Avail internship and job opportunities</strong> (also
                  the ones not openly advertised) in the political and policy
                  consulting space.
                </p>
              </div>
              <div className="flex-1 px-10 py-6 overflow-hidden text-center bg-white bg-center bg-no-repeat rounded-lg shadow-md ">
                <h4 className="mt-5 text-2xl font-medium font-poppins text-orange">
                  #3
                </h4>
                <p className="mt-3 text-darkBlue font-opensans">
                  <strong>Get mentored</strong> by experienced veterans of the
                  political and policy sectors.
                </p>
              </div>
            </div>
            <div className="text-center md:w-1/2 sm:w-full">
              <Text>
                Interns who have worked with our mentors in the past have gone
                on to become full-time staff members in political campaigns and
                in the offices of political and administrative leaders in Delhi.
              </Text>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="flex flex-col flex-wrap items-center justify-center w-full px-4 py-16 mx-auto max-w-7xl sm:pt-20 sm:px-6 md:pt-16 lg:py-28 lg:px-8 xl:pt-28">
            <div className="text-center md:w-1/2 sm:w-full">
              <h4 className="mb-6 text-3xl font-semibold font-poppins">
                <span className="text-orange">Features</span> of the ICPC
                Internship Programme
              </h4>
            </div>
            <div className="flex flex-col flex-wrap w-full gap-3 my-12 md:flex-row lg:w-2/3">
              <div className="flex-1 px-10 py-6 overflow-hidden text-center bg-white bg-no-repeat rounded-lg shadow-md ">
                <h4 className="mt-5 text-2xl font-medium font-poppins text-orange">
                  #1
                </h4>
                <p className="mt-3 text-darkBlue font-opensans">
                  Interns will be introduced to a wide set of perspectives on
                  the political economy through a combination of meticulously
                  curated, self-paced online and offline modules.
                </p>
              </div>
              <div className="flex-1 px-10 py-6 overflow-hidden text-center bg-white bg-center bg-no-repeat rounded-lg shadow-md ">
                <h4 className="mt-5 text-2xl font-medium font-poppins text-orange">
                  #2
                </h4>
                <p className="mt-3 text-darkBlue font-opensans">
                  Interns will get to play an active role in the offices of
                  political leadersand organizers and acquire hands-on
                  experience.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-bgGrey container-fluid">
          <div className="flex flex-wrap items-center w-full px-4 py-16 mx-auto max-w-7xl sm:pt-20 sm:px-6 md:pt-16 lg:py-28 lg:px-8 xl:pt-28">
            <div className="w-full md:w-3/4 sm:w-100">
              <h5 className="mb-6 text-2xl font-semibold text-darkBlue font-poppins">
                What will being an ICPC Intern entail?
              </h5>
              <Text className="mb-4 text-justify text-footerBlack">
                Interns will be introduced to a wide set of perspectives on the
                political economy through a combination of curated self paced
                training modules as well as online / offline tasks in the
                offices of political leaders, organizers and administrators. The
                programme is spread over 3 modules -
              </Text>
              <ul className={cn("list-none", styles.list)}>
                <li className="mb-2 font-opensans text-footerBlack">
                  Module 1 - Online Training
                </li>
                <li className="mb-2 font-opensans text-footerBlack">
                  Module 2 - 1 Month Campaign Internship (Online/Offline)
                </li>
                <li className="font-opensans text-footerBlack">
                  Module 3 - 2 month Client Facing Internship (Mostly Offline)
                </li>
              </ul>
              <Text className="mt-4 text-footerBlack">
                Interns will be mentored by individuals with experience in
                political and policy consulting across various states.
              </Text>
            </div>
            <div className="w-full mt-12 md:w-3/4 sm:w-100 sm:mt-16">
              <h5 className="mb-6 text-2xl font-semibold text-black font-poppins">
                Will I get a certificate on completion?
              </h5>
              <p className="mb-4 text-justify font-opensans text-footerBlack">
                Interns who have completed all 3 modules will receive a course
                completion certificate from PPGF.
              </p>
            </div>
            <div className="w-full mt-12 md:w-3/4 sm:w-100 sm:mt-16">
              <h5 className="mb-6 text-2xl font-semibold text-black font-poppins">
                What is the duration of the internship?
              </h5>
              <p className="mb-4 text-justify font-opensans text-footerBlack">
                Interns are expected to stay for four months (non-continuous).
                Given the differing academic schedules, the self-paced nature of
                the program allows students to spread out their tasks over a
                period of time (not more than 9 months).
              </p>
            </div>
            <div className="w-full mt-12 md:w-3/4 sm:w-100 sm:mt-16">
              <h5 className="mb-6 text-2xl font-semibold text-black font-poppins">
                Will Interns receive a stipend?
              </h5>
              <p className="mb-4 text-justify font-opensans text-footerBlack">
                Interns receive a stipend of Rs 5000 per month. A month here
                implies a module worth of tasks, which interns might choose to
                spread out due to academic commitments.
              </p>
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-neutral">
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium text-left uppercase tracking-widern font-poppins"
                  >
                    Module
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase font-poppins"
                  >
                    Stipend
                  </th>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  <tr>
                    <td className="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap font-opensans">
                      Module 1
                    </td>
                    <td className="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap font-opensans">
                      Rs. 5000
                    </td>
                  </tr>
                  <tr>
                    <td className="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap font-opensans">
                      Module 2
                    </td>
                    <td className="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap font-opensans">
                      Rs. 5000
                    </td>
                  </tr>
                  <tr>
                    <td className="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap font-opensans">
                      Module 3
                    </td>
                    <td className="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap font-opensans">
                      Rs. 10,000 (Rs. 5000 for each month)
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="w-full mt-12 md:w-3/4 sm:w-100 sm:mt-16">
              <h5 className="mb-6 text-2xl font-semibold text-black font-poppins">
                Who can apply for the internship?
              </h5>
              <p className="mb-4 text-justify font-opensans text-footerBlack">
                Students from all academic backgrounds are encouraged to apply
                to the ICPC Internship. However, the ICPC Internships are
                particularly recommended for final year or penultimate year
                students who are serious about entering the policy and political
                consulting space immediately after finishing their studies.
              </p>
            </div>
          </div>
        </div>

        <OtherInitiatives />
        <Footer />
      </div>
    );
};

export default ICPCInternship;