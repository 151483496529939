import React from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import Text from "../../../components/Text";
import OtherInitiatives from "../components/OtherInitiatives";

export const Workshop = () => {
  return (
    <div>
      <Header />
      <div className="bg-white container-fluid">
        <div className="flex flex-wrap items-center w-full px-4 py-16 mx-auto max-w-7xl sm:pt-20 sm:px-6 md:pt-16 lg:py-28 lg:px-8 xl:pt-28">
          <div className="w-full md:w-1/2 sm:w-100">
            <h1 className="mb-6 text-4xl font-semibold text-black font-poppins">
              PPGF <span className="text-orange">Workshop</span>
            </h1>
            <Text className="mb-4 text-justify">
              PPGF conducts regular workshops in collaboration with various
              institutes. These workshops are aimed at helping attendees equip
              themselves with the tools necessary to enter the policy and
              political consulting ecosystem.
            </Text>
            <Text className="mb-4 text-justify">
              All those interested can reach out to us on
              <a
                href="mailto: opportunities@ppgf.in"
                target="_blank"
                rel="noreferrer"
                className="ml-1 text-base font-medium"
              >
                opportunities@ppgf.in
              </a>
            </Text>
            {/* <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSe-YC7pT4p8nqM_kZNsKWTVE6acYqdFMHYADXc41DJU2PL9gQ/viewform"
              target="_blank"
              rel="noreferrer"
              className="inline-flex items-center justify-center px-4 py-2 mt-8 text-base font-medium text-white border border-transparent rounded-md shadow-sm whitespace-nowrap font-poppins bg-orange"
            >
              Apply Now
            </a> */}
          </div>
          <div className="flex justify-end w-full mt-8 md:w-1/2 md:mt-0">
            <img
              src="workshop.png"
              alt="Institutional Collaboration with IIDL"
              style={{ borderRadius: 8, width: "100%", maxWidth: 450 }}
            />
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="flex flex-col flex-wrap items-center justify-center w-full px-4 py-16 mx-auto max-w-7xl sm:pt-20 sm:px-6 md:pt-16 lg:py-28 lg:px-8 xl:pt-28">
          <div className="text-center md:w-1/2 sm:w-full">
            <h4 className="mb-6 text-3xl font-semibold font-poppins">
              <span className="text-orange">Benefits</span> of the Workshop
            </h4>
          </div>
          <div className="flex flex-col flex-wrap w-full gap-3 my-12 md:flex-row lg:w-2/3">
            <div className="flex-1 px-10 py-6 overflow-hidden text-center bg-white bg-no-repeat rounded-lg shadow-md ">
              <h4 className="mt-5 text-2xl font-medium font-poppins text-orange">
                #1
              </h4>
              <p className="mt-3 text-darkBlue font-opensans">
                Interact with and understand the intricacies of the policy and
                political consulting ecosystem from accomplished practitioners.
              </p>
            </div>
            <div className="flex-1 px-10 py-6 overflow-hidden text-center bg-white bg-center bg-no-repeat rounded-lg shadow-md ">
              <h4 className="mt-5 text-2xl font-medium font-poppins text-orange">
                #2
              </h4>
              <p className="mt-3 text-darkBlue font-opensans">
                Obtain an opportunity to enter into the PPGF Jobs Database by
                successfully completing the requirements of the workshop.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-bgGrey container-fluid">
        <div className="flex flex-wrap items-center w-full px-4 py-16 mx-auto max-w-7xl sm:pt-20 sm:px-6 md:pt-16 lg:py-28 lg:px-8 xl:pt-28">
          <div className="w-full md:w-3/4 sm:w-100">
            <h5 className="mb-6 text-2xl font-semibold text-black font-poppins">
              Who is the PPGF Workshop for?
            </h5>
            <p className="mb-4 text-justify font-opensans text-footerBlack">
              Many professionals and students interested in the field of
              political consulting and policy research may already be engaged in
              other commitments or professions and hence are unable to explore
              the field in depth. The workshop allows such people to gain an
              inside understanding of the political field in no more than two
              weeks.
            </p>
          </div>
          <div className="w-full mt-12 md:w-3/4 sm:w-100 sm:mt-16">
            <h5 className="mb-6 text-2xl font-semibold text-black font-poppins">
              How does the PPGF Workshop help?
            </h5>
            <p className="mb-4 text-justify font-opensans text-footerBlack">
              Many who are interested in the political and policy consulting
              ecosystem are often unable to act on their interests due to them
              not being equipped with the necessary tools and skill sets. The
              workshop ensures that they are equipped with the required tools to
              flourish in this ecosystem.
            </p>
          </div>
          <div className="w-full mt-12 md:w-3/4 sm:w-100 sm:mt-16">
            <h5 className="mb-6 text-2xl font-semibold text-black font-poppins">
              What is the duration, frequency and location of the workshops?
            </h5>
            <p className="mb-4 text-justify font-opensans text-footerBlack">
              The workshops last between 7 to 15 days and comprise of about
              20-25 participants each, with there being a total of 6 workshops
              in a year. These collaborations will be announced across various
              platforms in the future.
            </p>
          </div>
        </div>
      </div>
      <OtherInitiatives />
      <Footer />
    </div>
  );
};

export default Workshop;
