export const timelineList = [
  {
    date: "November 2020",
    title: "Conceptualization of PPGF.",
    icon: "conceptual.png"
  },
  {
    date: "March 2021",
    title: "Start of the First Batch of the ICPC Internship.",
    icon: "ICPC.png"
  },
  {
    date: "June 2021",
    title: "Creation of the PPGF Jobs Database and the start of PPGF Campaign Internships.",
    icon: "ppgfdatabase.png"
  },
  {
    date: "December 2021",
    title: "Start of the PPGF Workshop.",
    icon: "ppgfworkshop.png"
  },
  {
    date: "January 2022",
    title: "Restructuring of ICPC modules on the basis of learnings.",
    icon: "restructure.png"
  },
  {
    date: "March 2022",
    title: "Creation of the PPGF Tech Internship.",
    icon: "techInternship.png"
  },
  {
    date: "December 2022",
    title: "Completion of 10 batches of ICPC Internship and successful completion by upwards of 200 students.",
    icon: "completion.png"
  },
  {
    date: "May 2023",
    title: "Commencement of the Mukherjee Fellowship",
    icon: "fellowship.png"
  },

];