import React from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import Text from "../../../components/Text";
import OtherInitiatives from "../components/OtherInitiatives";

export const JobsDatabase = () => {
    return (
      <div>
        <Header />
        <div className="bg-white container-fluid">
          <div className="flex flex-wrap items-center w-full px-4 py-16 mx-auto max-w-7xl sm:pt-20 sm:px-6 md:pt-16 lg:py-28 lg:px-8 xl:pt-28">
            <div className="w-full md:w-1/2 sm:w-100">
              <h1 className="mb-6 text-4xl font-semibold text-black font-poppins">
                <span className="text-orange">Jobs</span> Database
              </h1>
              <Text className="mb-3 text-justify">
                The PPGF Jobs Database has been built with an intention to
                create a bona fide platform of several high-rewarding jobs and
                opportunities which otherwise can’t be advertised through normal
                means of recruitment.
              </Text>
              <Text className="mb-4 text-justify">
                Furthermore, the needs of the political offices are extremely
                specific with regard to language, skill set, local level
                awareness, as well as an understanding of politics in general.
                The PPGF Jobs Database aims to provide a solution to this
                situation by creating a database of vetted candidates and
                matching them with job opportunities in the political as well as
                policy consulting ecosystem.
              </Text>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSe-YC7pT4p8nqM_kZNsKWTVE6acYqdFMHYADXc41DJU2PL9gQ/viewform"
                target="_blank"
                rel="noreferrer"
                className="inline-flex items-center justify-center px-4 py-2 mt-8 text-base font-medium text-white border border-transparent rounded-md shadow-sm whitespace-nowrap font-poppins bg-orange"
              >
                Apply to Jobs Database
              </a>
            </div>
            <div className="flex justify-end w-full mt-8 md:w-1/2 md:mt-0">
              <img
                src="tech-people.png"
                alt="Institutional Collaboration with IIDL"
                style={{ borderRadius: 8, width: "100%", maxWidth: 450 }}
              />
            </div>
          </div>
        </div>

        <div className="bg-bgGrey container-fluid">
          <div className="flex flex-wrap items-center w-full px-4 py-16 mx-auto max-w-7xl sm:pt-20 sm:px-6 md:pt-16 lg:py-28 lg:px-8 xl:pt-28">
            <div className="w-full md:w-3/4 sm:w-100">
              <h5 className="mb-6 text-2xl font-semibold text-black font-poppins">
                What is the focus of the PPGF Jobs Database?
              </h5>
              <p className="mb-4 text-justify font-opensans text-footerBlack">
                To help young professionals enter into the world of politics and
                policy consulting, PPGF maintains a Jobs Database with a focus
                on states in the 2023 and 2024 election cycle.
              </p>
            </div>
            <div className="w-full mt-12 md:w-3/4 sm:w-100 sm:mt-16">
              <h5 className="mb-6 text-2xl font-semibold text-black font-poppins">
                What does the vetting process involve?
              </h5>
              <p className="mb-4 text-justify font-opensans text-footerBlack">
                The vetting process will involve a detailed interview as well as
                a task to assess the skill of the applicant. Applicants will be
                contacted by a representative of PPGF who will guide him/her
                throughout the vetting process.
              </p>
            </div>
            <div className="w-full mt-12 md:w-3/4 sm:w-100 sm:mt-16">
              <h5 className="mb-6 text-2xl font-semibold text-black font-poppins">
                What is the past record of PPGF Jobs Database?
              </h5>
              <p className="mb-4 text-justify font-opensans text-footerBlack">
                More than 60 professionals who had applied to this database and
                gone through the PPGF vetting process have received offers to
                work in different organizations on the basis of PPGF’s
                recommendations.
              </p>
            </div>
            <div className="w-full mt-12 md:w-3/4 sm:w-100 sm:mt-16">
              <h5 className="mb-6 text-2xl font-semibold text-black font-poppins">
                Who can apply to the PPGF Jobs Database?
              </h5>
              <p className="mb-4 text-justify font-opensans text-footerBlack">
                Young professionals from across academic backgrounds who wish to
                enter in the political and policy consulting space within the 20
                to 35 age group can apply to this database.
              </p>
            </div>
          </div>
        </div>
        <OtherInitiatives />
        <Footer />
      </div>
    );
};

export default JobsDatabase;