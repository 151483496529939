import React, {useCallback} from "react";
import cn from "classnames";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import ImageTestimonial from "../ImageTestimonial";
import RightArrow from "../../../../components/RightArrow";
import LeftArrow from "../../../../components/LeftArrow";

const arrowStyles = {
    top: 'calc(50%)',
    boxShadow: '0px 4px 16px rgba(26, 26, 26, 0.1)',
};


const TestimonialCarousel = ({ data }) => {
    const renderNextArrow = useCallback( (onClickHandler, hasNext, label) => {
        if (!hasNext) return null;
        return (
            <button type="button" className="inline-flex items-center p-3 rounded-full shadow-sm text-white bg-white right-2 absolute z-10 cursor-pointer" onClick={onClickHandler} title={label} style={{ ...arrowStyles }}>
                <RightArrow/>
            </button>
        )
    }, []);

    const renderPrevArrow = useCallback( (onClickHandler, hasPrev, label) => {
        if (!hasPrev) return null;
        return (
            <button type="button" className="inline-flex items-center p-3 rounded-full shadow-sm text-white bg-white left-2 absolute z-10 cursor-pointer" onClick={onClickHandler} title={label} style={{ ...arrowStyles }}>
                <LeftArrow/>
            </button>
        )
    }, []);

    const renderIndicator = useCallback((onClickHandler, isSelected, index, label) => {
        return (
            <li
                className={cn("w-2 h-2 mr-2 inline-block rounded mt-4", { "bg-orange": isSelected, "bg-neutral": !isSelected })}
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                value={index}
                key={index}
                role="button"
                tabIndex={0}
            />
        )
    }, []);

    return (
        <div style={{ width: "90vw", maxWidth: "inherit" }}>
            <Carousel
                showThumbs={false}
                showStatus={false}
                renderArrowNext={renderNextArrow}
                renderArrowPrev={renderPrevArrow}
                autoPlay
                interval={5000}
                renderIndicator={renderIndicator}
                dynamicHeight={true}
            >
                {data?.map((item, index) => (
                    <ImageTestimonial key={index} className="w-full text-left mb-8" name={item.name} designation={item.designation} image={item.image} link={item.link}>
                        {item.testimonial}
                    </ImageTestimonial>
                ))}
            </Carousel>
        </div>
    )
};

export default TestimonialCarousel;