export const routes = {
    root: '/',
    icpcInternship: '/icpc-internship',
    campaignInternship: '/campaign-internship',
    jobsDatabase: '/jobs-database',
    techInternship: '/tech-internship',
    workshop: '/workshop',
    team: '/team',
    contact: '/contact',
    testimonials: '/testimonials',
    ourJourney:'/our-journey'
};

export default routes;