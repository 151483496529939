import Text from "../Text";
import { Link, useHistory } from "react-router-dom";
import routes from "../../routes";
import { useEffect } from "react";

const Footer = () => {
  const { location } = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div
      className="w-full px-8 lg:px-24 xl:px-48"
      style={{
        background:
          "linear-gradient(360deg, #F3F5F9 49.92%, rgba(243, 245, 249, 0) 100%)",
      }}
    >
      <div className="container">
        <div className="grid grid-cols-2 py-12 md:grid-cols-4 md:py-24 lg:py-24">
          <div className="col-span-4 mb-12 md:col-span-1 md:mb-0">
            <img src="ppgf-header.png" alt="PPGF logo" />
            <Text className="my-6 text-sm text-footerBlack">
              Creating opportunities for students and young professionals in
              policy, politics & governance
            </Text>
            <div className="flex flex-wrap gap-4">
              <a
                href="https://twitter.com/PPGFIndia"
                target="_blank"
                rel="noreferrer"
              >
                <img src="twitter-icon.png" alt="Facebook" />
              </a>
              <a
                href="https://www.facebook.com/PPGFIndia"
                target="_blank"
                rel="noreferrer"
              >
                <img src="facebook-icon.png" alt="Facebook" />
              </a>
              <a
                href="https://www.instagram.com/ppgf_india/"
                target="_blank"
                rel="noreferrer"
              >
                <img src="instagram-icon.png" alt="Facebook" />
              </a>
              <a
                href="https://www.linkedin.com/company/ppgf"
                target="_blank"
                rel="noreferrer"
              >
                <img src="linkedin-icon.png" alt="Facebook" />
              </a>
            </div>
          </div>

          <div className="flex flex-col col-start-1 gap-6 md:col-start-3">
            <Link to={routes.root}>
              <Text className="font-medium text-footerBlack">Home</Text>
            </Link>
            <Link to={routes.ourJourney}>
              <Text className="font-medium text-footerBlack">
                Journey So Far
              </Text>
            </Link>
            <Link to={routes.team}>
              <Text className="font-medium text-footerBlack">Team</Text>
            </Link>
            {/* <Link to="/">
                        <Text className="font-medium text-footerBlack">Reading List</Text>
                    </Link> */}
            <Link to={routes.testimonials}>
              <Text className="font-medium text-footerBlack">Testimonials</Text>
            </Link>
            <Link to={routes.contact}>
              <Text className="font-medium text-footerBlack">Contact us</Text>
            </Link>
          </div>

          <div className="flex flex-col gap-6">
            <Link
              to={{ pathname: "https://www.vajpayee.ppgf.in/" }}
              target="_blank"
            >
              <Text className="font-medium text-footerBlack">VPP</Text>
            </Link>
            <Link
              to={{ pathname: "https://mukherjee.ppgf.in/" }}
              target="_blank"
            >
              <Text className="font-medium text-footerBlack">
                Mukherjee Fellowship
              </Text>
            </Link>
            <Link to={routes.icpcInternship}>
              <Text className="font-medium text-footerBlack">
                ICPC Internship
              </Text>
            </Link>
            <Link to={routes.campaignInternship}>
              <Text className="font-medium text-footerBlack">
                PPGF Campaign Internship
              </Text>
            </Link>
            <Link to={routes.techInternship}>
              <Text className="font-medium text-footerBlack">
                Tech Internship
              </Text>
            </Link>
            <Link to={routes.jobsDatabase}>
              <Text className="font-medium text-footerBlack">
                Jobs Database
              </Text>
            </Link>
            <Link to={routes.workshop}>
              <Text className="font-medium text-footerBlack">Workshop</Text>
            </Link>
            {/* <Link to="/">
                        <div
                            className="inline-flex items-center justify-center px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm whitespace-nowrap font-poppins bg-orange"
                        >
                            Apply Now
                        </div>
                    </Link> */}
          </div>
        </div>
        <div
          className="flex flex-wrap items-center py-8 mx-auto"
          style={{ borderColor: "rgba(26, 26, 26, 0.2)", borderTopWidth: 1 }}
        >
          <p className="text-sm font-opensans text-footerBlack">
            © 2023 PPGF. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
