export const mentorsList = [
    {
        picture: "devendra-pai.png",
        name: "Devendra Pai",
        description:
            "Devendra Pai is the Chief Academic Officer of Rambhau Mhalgi Prabodhini and the Course Head of Indian Institute of Democratic Leadership.",
    },
    {
        picture: "harsh-gupta.png",
        name: "Harsh Gupta",
        description:
            "Harsh Gupta ‘Madhusudan’ is an India-based public markets investor. He has written extensively on economics, finance and politics for Mint, Swarajya, The Wall Street Journal, The Indian Express and other publications.",
    },
    {
        picture: "rajeev-mantri.png",
        name: "Rajeev Mantri",
        description:
            "Rajeev Mantri is an India-based entrepreneur and investor, investing in public companies and early-stage technology ventures. He has contributed many articles to Mint, Swarajya, The Wall Street Journal, The Indian Express and other publications.",
    },
    {
        picture: "ashish-chandorkar.png",
        name: "Aashish Chandorkar",
        description:
            "Aashish Chandorkar writes on public policy and politics. His articles have been published in several newspapers, magazines and web portals. He is based in Pune. He tweets at @c_aashish.",
    },
    {
        picture: "rohit-jayaraman.png",
        name: "Rohit Jayaraman",
        description:
            "Rohit G Jayaraman is a corporate lawyer who has also worked as a political consultant.",
    },
    {
        picture: "rohit-pathania.png",
        name: "Rohit Pathania",
        description:
            "Rohit Pathania is an analyst in the domains of public policy and governance. With a background in energy and environment, he has also written extensively on other domains of policy making, including economy, finance, history and politics.",
    },
    {
        picture: "praveen-singh.png",
        name: "Praveen Singh",
        description:
            "Praveen Singh is a political and corporate consultant from Bundelkhand, UP. He has worked in multiple elections across India since 2013. He is currently working on multiple initiatives aimed at the long term economic development of Bundelkhand through the Kannan Foundation.",
    },
    {
        picture: "pratyasha.png",
        name: "Pratyasha",
        description:
            "Pratyasha is a researcher working in the development sector with a specialization in qualitative research and applied behavioral science. She is a keen observer of politics, policy and culture and often writes and comments about issues in the intersection of these spaces.",
    },
    {
        picture: "shubhendu-anand.png",
        name: "Shubhendu Anand",
        description:
            "Shubhendu Anand is an Advocate practicing in the Supreme Court of India. He is also a Partner at a New Delhi based law form where he heads the regulatory and policy practice. His articles on law and politics have been published in various newspapers, journals and web portals.",
    },
    {
        picture: "praful-shankar.png",
        name: "Praful Shankar",
        description:
            "Praful is a consultant with wide experience in the corporate sector. He is a political columnist who writes for the magazine Swarajya.",
    },
    {
        picture: "rangesh-shridhar.png",
        name: "Rangesh Shridhar",
        description:
            "Rangesh Sridhar is a consultant with wide experience in the corporate world and startups.",
    },
    {
        picture: "hari.png",
        name: "Hari",
        description:
            "Hari is a researcher working in the political consulting sector. He has a background in economics and specializes in issues relating to economics and history."
    },
    {
        picture: "kalpojyoti.png",
        name: "Kalpojyoti Kashyap",
        description:
            "Kalpojyoti Kashyap is a consultant in the political sector and was previously an editor of the news portal, OpIndia. He has experience in journalism and psychology and is a co-author of the book, Delhi Anti-Hindu Riots 2020."
    },
];

export const teamMembersList = [
  {
    picture: "shreyas-bhardwaj.png",
    name: "Shreyas  Bhardwaj",
    description:
      "Shreyas Bharadwaj is a political consultant from Mysuru who has worked in multiple elections since 2017. He is interested in understanding the impact that urbanization and economic growth are having on India's polity.",
    title: "Programme Coordinator",
  },
  {
    picture: "poulasta-chakraborty.png",
    name: "Poulasta Chakraborty",
    description:
      "Poulasta Chakraborty is a graduate from Christ University who has worked as a commentator on socio-political issues for IndiaFacts and Swarajya. His primary areas of interest include history and politics of India, Middle-East, and West Europe.",
    title: "Training Coordinator",
  },
  {
    picture: "kaushik-reddy.png",
    name: "Kaushik Reddy",
    description:
      "Kaushik Reddy is currently working as a political consultant in Bengaluru. An engineer by training, he has experience in diverse fields like Mobile & Satellite Communications, Education & Psychology.",
    title: "Internship Coordinator",
  },
];