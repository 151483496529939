import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import TeamMember from "../../components/TeamMember";
import { mentorsList } from "./config";
import "./Team.css";

const Team = () => {
  return (
    <div>
      <Header />
      <div className="bg-white container-fluid">
        <div className="w-full flex flex-wrap items-center mx-auto max-w-7xl px-4 py-12 sm:pt-12 sm:px-6 md:pt-16 lg:py-20 lg:px-8 xl:pt-28 h-70v flex items-center">
          <div className="w-full md:w-3/4 sm:w-100">
            <h4 className="text-3xl text-black font-semibold mb-6 font-poppins">
              <span className="block xl:inline leading-normal">
                <span className="text-orange">At PPGF, </span>we have experts and specialists from a variety of fields who 
                are dedicated to the mission of creating opportunities in the fields of politics, policy and governance.
              </span>
            </h4>
          </div>
        </div>
      </div>
      <div className="bg-white container-fluid mb-28">
        <div className="w-full flex flex-row mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <h5 className="font-poppins font-semibold separator">Our Mentors</h5>
        </div>
        <div className="w-full flex flex-wrap flex-row mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          {mentorsList.map((item) => (
            <TeamMember
              picture={item.picture}
              name={item.name}
              description={item.description}
            />
          ))}
        </div>
      </div>
      {/* <div className="bg-white container-fluid my-28">
        <div className="w-full flex flex-row mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <h5 className="font-poppins font-semibold separator">Our Team</h5>
        </div>
        <div className="w-full flex flex-wrap flex-row mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          {teamMembersList.map((item) => (
            <TeamMember
              picture={item.picture}
              name={item.name}
              description={item.description}
              title={item.title}
            />
          ))}
        </div>
      </div> */}
      <Footer />
    </div>
  );
};

export default Team;
