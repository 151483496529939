import React, {useCallback} from "react";
import cn from "classnames";
import BlockQuote from "../../../../components/BlockQuote";

const CompactTestimonial = ({ children, className, name, designation, image, link }) => {
    const handleClick = useCallback(() => {
        if (link) {
            window.open(link, "_blank");
        }
    }, [link]);

    return (
        <div className={cn("pl-16 lg:pl-0", { [className || '']: className })}>
            <BlockQuote className="text-justify">
                {children}
            </BlockQuote>

            <footer className="mt-6">
                <div className={cn("inline-flex items-center", { "cursor-pointer": !!link })} onClick={handleClick}>
                    {image &&
                        <div className="flex-shrink-0 inline-flex rounded-full border-2 border-white mr-4">
                            <img
                                className="h-16 w-16 rounded-full"
                                src={image}
                                alt=""
                            />
                        </div>
                    }
                    <div>
                        <div className="font-poppins text-footerBlack text-xl font-semibold">{name}</div>
                        <div className="text-txtGrey font-poppins opacity-70 text-sm mt-1">{designation}</div>
                    </div>
                </div>
            </footer>
        </div>
    )
};

export default CompactTestimonial;