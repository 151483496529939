import React, { useMemo } from "react";
import BlogCard from "../../../../components/BlogCard";
import {useRouteMatch} from "react-router-dom";
import routes from "../../../../routes";
import TestimonialCarousel from "../../../Testimonials/components/TestimonialCarousel";
import {internTestimonials} from "../../../Testimonials/config";
import ViewAll from "../../../../components/ViewAll";

const OtherInitiatives = () => {
    const { path } = useRouteMatch();

    const initiativesList = useMemo(() => {
        const items = [];
        if (path !== routes.icpcInternship) {
            items.push({
                title: "ICPC Internship Programme",
                description: 'The PPGF Introductory Course in Political Consulting (ICPC) Program is aimed at introducing students from institutions across India to the basics of policy, politics, governance. The program equips students with the foundational skills of working in the field of politics by offering practical opportunities in the political and policy consulting space.',
                image: "icpc-person.png",
                link: routes.icpcInternship,
            });
        }
        if (path !== routes.campaignInternship) {
            items.push({
                title: "PPGF Campaign Internship",
                description: 'The PPGF Campaign Internship Program facilitates opportunities for students to intern in election campaigns in real-time across election going states in India for a national political party.',
                image: "campaign-group.png",
                link: routes.campaignInternship,
            });
        }
        if (path !== routes.techInternship) {
            items.push({
                title: "Tech Internship",
                description: 'The PPGF Tech Internship aims to allow students to intern in the political campaigns of a national political party in various roles relating to election forecasting, survey analysis, building data pipelines and automating tasks.',
                image: "tech-internship.png",
                link: routes.techInternship,
            });
        }
        if (path !== routes.jobsDatabase) {
            items.push({
                title: "Jobs Database",
                description: "The PPGF Jobs Database serves to facilitate job opportunities within the political consultancy and policy consulting ecosystem that can't be advertised through normal means of recruitment by the employers and stakeholders.",
                image: "tech-people.png",
                link: routes.jobsDatabase,
            });
        }
        if (path !== routes.workshop) {
            items.push({
                title: "PPGF Workshop",
                description: 'The PPGF Workshop is a weeklong curated program for young professionals and students who wish to equip themselves to work in the policy and political consulting ecosystem.',
                image: "workshop.png",
                link: routes.workshop,
            });
        }

        return items;
    }, [path]);

    return (
        <>
        {/*Testimonials Start*/}
        <div className="bg-white container-fluid">
            <div className="w-full flex flex-wrap items-center py-16 mx-auto max-w-7xl px-4 sm:pt-20 sm:px-6 md:pt-16 lg:py-28 lg:px-8 xl:pt-28">
                <div className="w-full flex flex-row mx-auto max-w-7xl px-4 lg:px-0">
                    <h4 className="text-3xl mt-6 md:mt-0 text-black font-semibold mb-24 font-poppins">
                        What Our Interns <span className="text-orange">Say</span>
                    </h4>
                </div>
                <TestimonialCarousel data={internTestimonials} />
                <ViewAll className="mt-8" link={routes.testimonials}>View all testimonials</ViewAll>
            </div>
        </div>
        {/*Testimonials End*/}

        <div className="bg-white container-fluid">
            <div className="w-full flex flex-wrap items-center py-16 mx-auto max-w-7xl px-4 sm:pt-20 sm:px-6 md:pt-16 lg:py-28 lg:px-8 xl:pt-28">
                <div className="w-full flex flex-row mx-auto max-w-7xl px-4 lg:px-0 mb-12">
                    <h5 className="font-poppins font-semibold separator text-2xl">Other Initiatives</h5>
                </div>
                <div className="flex flex-col md:flex-row flex-wrap w-full my-12 gap-6">
                    {initiativesList.map((item, index) => (
                        <BlogCard key={index} blog={item} />
                    ))}
                </div>
            </div>
        </div>
        </>
    )
};

export default OtherInitiatives;