import * as React from "react";

const LeftArrow = (props) => (
    <svg
        width={17}
        height={16}
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M16 8L1 8"
            stroke="#FF391E"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8 1L0.999999 8L8 15"
            stroke="#FF391E"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default LeftArrow;
