import React from "react";
import cn from "classnames";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import styles from './styles.module.scss';
import Text from "../../components/Text";
import ViewAll from "../../components/ViewAll";
// import BlogCard from "../../components/BlogCard";
// import {blogList} from "./config";
import routes from "../../routes";
import TestimonialCarousel from "../Testimonials/components/TestimonialCarousel";
import {ministerTestimonials} from "../Testimonials/config";

const App = () => {
  return (
    <div>
      <Header isHome />
      {/*Hero Start*/}
      <div
        className="h-100v"
        style={{
          background:
            "linear-gradient(90deg, rgba(10, 27, 45, 0.7) 36.84%, rgba(10, 27, 45, 0.3) 96.04%), url(/parliament-building.png)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          marginTop: -92,
        }}
      >
        <main className="flex items-center px-4 pt-10 mx-auto max-w-7xl sm:pt-12 sm:px-6 md:pt-16 lg:py-20 lg:px-8 xl:pt-28 h-100v">
          <div className="lg:text-left">
            <h1
              className={cn(
                "text-3xl font-medium font-poppins text-white sm:text-5xl md:text-4xl lg:w-8/12",
                styles.title
              )}
            >
              <span className="block leading-snug xl:inline">
                Creating Opportunities for Students and Young Professionals
                in&nbsp;
              </span>
              <span
                className="block leading-snug underline xl:inline"
                style={{
                  textDecorationColor: "#FF391E",
                  textUnderlineOffset: 8,
                }}
              >
                Policy, Politics & Governance
              </span>
            </h1>
            {/* <div className="mt-5 sm:mt-8 sm:flex sm:justify-center md:justify-start">
              <div className="rounded-md shadow">
                <a
                  href="https://mukherjee.ppgf.in/apply"
                  target="_blank"
                  className="flex items-center justify-center w-full px-8 py-2 text-base font-medium text-white border border-transparent rounded-md font-poppins bg-orange md:py-2 md:text-lg md:px-10"
                  rel="noreferrer"
                >
                  Apply Now
                </a>
              </div>
            </div> */}
          </div>
        </main>
      </div>
      {/*Hero End*/}

      {/*About PPGF Start*/}
      <div className="bg-neutral container-fluid">
        <div className="flex flex-col flex-wrap items-center justify-center w-full px-4 py-16 mx-auto max-w-7xl sm:pt-20 sm:px-6 md:pt-16 lg:py-28 lg:px-8 xl:pt-28">
          <div className="text-center md:w-1/2 sm:w-full">
            <p className="mb-4 leading-7 text-darkBlue font-opensans">
              The Policy, Politics and Governance Foundation (PPGF) has been
              established to create exclusive opportunities for students and
              young professionals interested in the intersection of policy,
              politics and governance.
            </p>
          </div>
          <div className="flex flex-col flex-wrap w-full gap-3 my-12 md:flex-row">
            <div
              className="flex-1 px-10 py-6 overflow-hidden text-center bg-white bg-no-repeat rounded-lg shadow "
              style={{ backgroundImage: "url(/letter-P.png)" }}
            >
              <h4 className="mt-5 text-2xl font-medium font-poppins text-orange">
                Policies
              </h4>
              <p className="mt-3 text-darkBlue font-opensans">
                A deliberate set of rules and guidelines put in place by an
                administrative set-up to achieve desired results and outcomes.
              </p>
            </div>
            <div
              className="flex-1 px-10 py-6 overflow-hidden text-center bg-white bg-center bg-no-repeat rounded-lg shadow "
              style={{ backgroundImage: "url(/letter-P.png)" }}
            >
              <h4 className="mt-5 text-2xl font-medium font-poppins text-orange">
                Politics
              </h4>
              <p className="mt-3 text-darkBlue font-opensans">
                A set of activities associated with decision making in groups
                with the aim of obtaining power to push a specific policy
                agenda.
              </p>
            </div>
            <div
              className="flex-1 px-10 py-6 overflow-hidden text-center bg-white bg-right bg-no-repeat rounded-lg shadow "
              style={{ backgroundImage: "url(/letter-G.png)" }}
            >
              <h4 className="mt-5 text-2xl font-medium font-poppins text-orange">
                Governance
              </h4>
              <p className="mt-3 text-darkBlue font-opensans">
                An organized interaction between the government and its society
                through norms, laws and power structures, thus closely linking
                it to both politics and policy.
              </p>
            </div>
          </div>
          <div className="text-center md:w-1/2 sm:w-full">
            <Text>
              Without understanding politics in India, one can not hope to
              advise on policy and governance. Similarly, without an
              understanding of the basics of policy and governance in India,
              one’s advice on political affairs will be hamstrung and impotent.
            </Text>
          </div>
        </div>
      </div>
      {/*About PPGF End*/}

      {/*Journey Start*/}
      <div className="bg-white container-fluid">
        <div className="flex flex-wrap items-center w-full px-4 py-16 mx-auto max-w-7xl sm:pt-20 sm:px-6 md:pt-16 lg:py-28 lg:px-8 xl:pt-28">
          <div className="flex w-full mt-8 md:w-1/2 justify-left md:mt-0">
            <img
              src="parliment.jpg"
              alt="Institutional Collaboration with IIDL"
              style={{ borderRadius: 8, width: "100%", maxWidth: 450 }}
            />
          </div>
          <div className="w-full pl-3 md:w-1/2 sm:w-100">
            <h4 className="mt-6 mb-6 text-3xl font-semibold text-black md:mt-0 font-poppins">
              <span className="text-orange">Mukherjee</span> Fellowship
            </h4>
            <Text className="text-justify">
              <span className="font-bold">
                Policy, Politics and Governance Foundation (PPGF),
              </span>{" "}
              in collaboration with{" "}
              <span className="font-bold">
                Nation First Policy Research Centre (NFPRC),
              </span>{" "}
              is offering a unique fellowship opportunity to provide the
              brightest minds across India a hands-on experience of working
              closely with premier legislators and politicians in the country
              for a year. Through thorough training, highest level of placement
              and continuous coordination with subject matter experts on a wide
              range of subjects, the Fellowship aims to set young professionals
              up for a successful career in the space.
            </Text>
          </div>
        </div>
      </div>
      {/*Journey End*/}

      {/*Initiatives Start*/}
      <div className="bg-bgGrey container-fluid">
        <div className="flex flex-wrap items-center w-full px-4 py-16 mx-auto max-w-7xl sm:pt-20 sm:px-6 md:pt-16 lg:py-28 lg:px-8 xl:pt-28">
          <div className="flex flex-row w-full px-4 mx-auto max-w-7xl lg:px-0">
            <h5 className="text-2xl font-semibold font-poppins separator">
              Our Initiatives
            </h5>
          </div>

          <div className="flex flex-wrap items-center w-full mx-auto max-w-7xl sm:pt-10 lg:py-16">
            <div className="flex w-full mt-8 md:w-1/2 justify-left md:mt-0">
              <img
                src="icpc-person.png"
                alt="Institutional Collaboration with IIDL"
                style={{ borderRadius: 8, width: "100%", maxWidth: 450 }}
              />
            </div>
            <div className="w-full pl-3 md:w-1/2 sm:w-100">
              <h4 className="mt-6 mb-6 text-3xl font-semibold text-black md:mt-0 font-poppins">
                <span className="text-orange">ICPC</span> Internship Programme
              </h4>
              <Text className="text-justify">
                The{" "}
                <span className="text-orange">
                  PPGF Introductory Course in Political Consulting (ICPC)
                  Program
                </span>{" "}
                is aimed at introducing students from institutions across India
                to the basics of policy, politics, governance. The program
                equips students with the foundational skills of working in the
                field of politics by offering practical opportunities in the
                political and policy consulting space.
              </Text>
              <ViewAll className="mt-8" link={routes.icpcInternship}>
                View details
              </ViewAll>
            </div>
          </div>

          <div className="flex flex-col-reverse flex-wrap items-center w-full pt-16 mx-auto md:flex-row max-w-7xl lg:py-24">
            <div className="w-full pl-3 md:w-1/2 sm:w-100">
              <h4 className="mt-6 mb-6 text-3xl font-semibold text-black md:mt-0 font-poppins">
                PPGF <span className="text-orange">Campaign</span> Internship
              </h4>
              <Text className="text-justify">
                The{" "}
                <span className="text-orange">
                  PPGF Campaign Internship Program
                </span>{" "}
                facilitates opportunities for students to intern in election
                campaigns in real-time across election going states in India for
                a national political party.
              </Text>
              <ViewAll className="mt-8" link={routes.campaignInternship}>
                View details
              </ViewAll>
            </div>
            <div className="flex justify-center w-full mt-8 md:w-1/2 md:justify-left md:mt-0">
              <img
                src="campaign-group.png"
                alt="Institutional Collaboration with IIDL"
                style={{ borderRadius: 8, width: "100%", maxWidth: 450 }}
              />
            </div>
          </div>

          <div className="flex flex-wrap items-center w-full mx-auto max-w-7xl sm:pt-16 lg:py-24">
            <div className="flex w-full mt-8 md:w-1/2 justify-left md:mt-0">
              <img
                src="tech-people.png"
                alt="Institutional Collaboration with IIDL"
                style={{ borderRadius: 8, width: "100%", maxWidth: 450 }}
              />
            </div>
            <div className="w-full pl-3 md:w-1/2 sm:w-100">
              <h4 className="mt-6 mb-6 text-3xl font-semibold text-black md:mt-0 font-poppins">
                <span className="text-orange">Jobs</span> Database
              </h4>
              <Text className="text-justify">
                The <span className="text-orange">PPGF Jobs Database</span>{" "}
                serves to facilitate job opportunities within the political
                consultancy and policy consulting ecosystem that can't be
                advertised through normal means of recruitment by the employers
                and stakeholders.
              </Text>
              <ViewAll className="mt-8" link={routes.jobsDatabase}>
                View details
              </ViewAll>
            </div>
          </div>

          <div className="flex flex-col-reverse flex-wrap items-center w-full pt-16 mx-auto md:flex-row max-w-7xl lg:py-24">
            <div className="w-full pl-3 md:w-1/2 sm:w-100">
              <h4 className="mt-6 mb-6 text-3xl font-semibold text-black md:mt-0 font-poppins">
                <span className="text-orange">Tech</span> Internship
              </h4>
              <Text className="text-justify">
                The <span className="text-orange">PPGF Tech Internship</span>{" "}
                aims to allow students to intern in the political campaigns of a
                national political party in various roles relating to election
                forecasting, survey analysis, building data pipelines and
                automating tasks.
              </Text>
              <ViewAll className="mt-8" link={routes.techInternship}>
                View details
              </ViewAll>
            </div>
            <div className="flex justify-center w-full mt-8 md:w-1/2 md:justify-left md:mt-0">
              <img
                src="tech-internship.png"
                alt="Institutional Collaboration with IIDL"
                style={{ borderRadius: 8, width: "100%", maxWidth: 450 }}
              />
            </div>
          </div>

          <div className="flex flex-wrap items-center w-full mx-auto max-w-7xl sm:pt-16 lg:py-24">
            <div className="flex w-full mt-8 md:w-1/2 justify-left md:mt-0">
              <img
                src="workshop.png"
                alt="Institutional Collaboration with IIDL"
                style={{ borderRadius: 8, width: "100%", maxWidth: 450 }}
              />
            </div>
            <div className="w-full pl-3 md:w-1/2 sm:w-100">
              <h4 className="mt-6 mb-6 text-3xl font-semibold text-black md:mt-0 font-poppins">
                PPGF <span className="text-orange">Workshop</span>
              </h4>
              <Text className="text-justify">
                The <span className="text-orange">PPGF Workshop</span> is a
                week-long curated program for young professionals and students
                who wish to equip themselves to work in the policy and political
                consulting ecosystem.
              </Text>
              <ViewAll className="mt-8" link={routes.workshop}>
                View details
              </ViewAll>
            </div>
          </div>
        </div>
        {/*Initiatives End*/}

        {/*Testimonials Start*/}
        <div className="bg-white container-fluid">
          <div className="flex flex-wrap items-center w-full px-4 py-16 mx-auto max-w-7xl sm:pt-20 sm:px-6 md:pt-16 lg:py-28 lg:px-8 xl:pt-28">
            <div className="flex flex-row w-full px-4 mx-auto max-w-7xl lg:px-0">
              <h4 className="mt-6 mb-24 text-3xl font-semibold text-black md:mt-0 font-poppins">
                What They <span className="text-orange">Say</span> About Us
              </h4>
            </div>
            <TestimonialCarousel data={ministerTestimonials} />
            <ViewAll className="mt-8" link={routes.testimonials}>
              View all testimonials
            </ViewAll>
          </div>
        </div>
        {/*Testimonials End*/}

        {/*Blogs Start*/}
        {/*<div className="bg-white container-fluid">
          <div className="flex flex-wrap items-center w-full px-4 py-16 mx-auto max-w-7xl sm:pt-20 sm:px-6 md:pt-16 lg:py-28 lg:px-8 xl:pt-28">
            <div className="flex flex-row w-full px-4 mx-auto max-w-7xl lg:px-0">
              <h4 className="mt-6 mb-6 text-3xl font-semibold text-black md:mt-0 font-poppins">
                Our Blogs
              </h4>
            </div>
            <div className="flex flex-col flex-wrap w-full gap-6 my-12 md:flex-row">
              {blogList.map((blog, index) => (
                  <BlogCard key={index} openInNewTab blog={blog} />
              ))}
            </div>
          </div>
        </div>*/}
        {/*Blogs End*/}
      </div>
      <Footer />
    </div>
  );
};

export default App;
