import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const InternshipApply = () => {
  const [name, setName] = useState('');
  return (
    <div>
      <Header />
      <div className="flex flex-wrap justify-center w-full mt-8 mb-4 ml-4 -mx-3 md:ml-0">
        <h4 className="mb-6 text-3xl font-semibold text-black font-poppins">
          <span className="block leading-normal xl:inline">
            Apply for the ICPC Internship Programme
          </span>
        </h4>
      </div>
      <div className="flex items-center justify-center px-4 pb-12 w-100">
        <form
          className="w-full max-w-lg"
          action="https://formsubmit.co/9f02d1c7f3cb917cfc47f097efa67125"
          method="POST"
          enctype="multipart/form-data"
        >
          <div className="flex flex-wrap mb-4 -mx-3">
            <div className="w-full px-3">
              <label
                className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                for="grid-name"
              >
                Full Name
              </label>
              <input
                className="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 border rounded appearance-none bg-bgGrey border-bgGrey focus:outline-none focus:bg-white"
                id="grid-first-name"
                type="text"
                placeholder="John Doe"
                required
                name="name"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
          <div className="flex flex-wrap mb-4 -mx-3">
            <div className="w-full px-3">
              <label
                className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                for="grid-password"
              >
                E-mail
              </label>
              <input
                className="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 border rounded appearance-none bg-bgGrey border-bgGrey focus:outline-none focus:bg-white focus:border-gray-500"
                id="email"
                type="email"
                placeholder="john@gmail.com"
                required
                name="email"
              />
            </div>
          </div>
          <div className="flex flex-wrap mb-4 -mx-3">
            <div className="w-full px-3">
              <label
                className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                for="grid-subject"
              >
                Phone Number
              </label>
              <input
                className="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 border rounded appearance-none bg-bgGrey border-bgGrey focus:outline-none focus:bg-white focus:border-gray-500"
                id="phoneNumber"
                type="number"
                required
                name="phoneNumber"
              />
            </div>
          </div>
          <div className="flex flex-wrap mb-4 -mx-3">
            <div className="w-full px-3">
              <label
                className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                for="grid-subject"
              >
                Gender
              </label>
              <input
                className="block w-full px-4 py-3 leading-tight text-gray-700 border rounded appearance-none bg-bgGrey border-bgGrey focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-gender"
                type="text"
                required
                name="gender"
              />
            </div>
          </div>
          <div className="flex flex-wrap mb-4 -mx-3">
            <div className="w-full px-3">
              <label
                className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                for="grid-subject"
              >
                Age
              </label>
              <input
                className="block w-full px-4 py-3 leading-tight text-gray-700 border rounded appearance-none bg-bgGrey border-bgGrey focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-age"
                type="text"
                required
                name="age"
              />
            </div>
          </div>
          <div className="flex flex-wrap mb-4 -mx-3">
            <div className="w-full px-3">
              <label
                className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                for="grid-subject"
              >
                Homestate
              </label>
              <input
                className="block w-full px-4 py-3 leading-tight text-gray-700 border rounded appearance-none bg-bgGrey border-bgGrey focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-age"
                type="text"
                required
                name="homestate"
              />
            </div>
          </div>
          <div className="flex flex-wrap mb-4 -mx-3">
            <div className="w-full px-3">
              <label
                className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                for="grid-subject"
              >
                College
              </label>
              <input
                className="block w-full px-4 py-3 leading-tight text-gray-700 border rounded appearance-none bg-bgGrey border-bgGrey focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-college"
                type="text"
                required
                name="college"
              />
            </div>
          </div>
          <div className="flex flex-wrap mb-4 -mx-3">
            <div className="w-full px-3">
              <label
                className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                for="grid-subject"
              >
                Programme
              </label>
              <input
                className="block w-full px-4 py-3 leading-tight text-gray-700 border rounded appearance-none bg-bgGrey border-bgGrey focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-programme"
                type="text"
                required
                name="programme"
              />
            </div>
          </div>
          <div className="flex flex-wrap mb-4 -mx-3">
            <div className="w-full px-3">
              <label
                className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                for="grid-subject"
              >
                Graduating Year
              </label>
              <input
                className="block w-full px-4 py-3 leading-tight text-gray-700 border rounded appearance-none bg-bgGrey border-bgGrey focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-year"
                type="number"
                required
                name="graduating-year"
              />
            </div>
          </div>
          <div className="flex flex-wrap mb-4 -mx-3">
            <div className="w-full px-3">
              <label
                className="block text-xs font-bold tracking-wide text-gray-700 uppercase"
                for="grid-subject"
              >
                Upload CV
              </label>
              <input
                className="w-full py-3 leading-tight text-gray-700 appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-year"
                type="file"
                required
                name="cv"
                accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              />
            </div>
            <p className="px-4 text-xs text-gray-600 font-poppins">
              Only .pdf .doc or .docx files are allowed
            </p>
          </div>

          <input
            type="hidden"
            required
            name="_subject"
            value={`New Internship Application - ${name}`}
          />

          <div className="md:flex md:items-center">
            <div className="mt-2 md:w-1/3">
              <button
                className="px-4 py-2 font-medium text-white rounded shadow bg-orange hover:bg-orange focus:shadow-outline focus:outline-none font-poppins"
                type="submit"
              >
                Send
              </button>
            </div>
            <div className="md:w-2/3"></div>
          </div>
          <input type="text" name="_honey" className="hidden" />
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default InternshipApply;
