const TeamMember = ({ picture, name, description, title }) => {
  return (
    <div className="w-full md:w-1/2 lg:w-4/12 mt-8">
      <img src={picture} alt={name} style={{ borderRadius: 8 }} />
      <h4 className="text-2xl text-black font-semibold mb-6 mt-8 font-poppins">
        {name}
      </h4>
      {title && (
        <h6
          className="font-poppins mb-4 font-medium"
          style={{ marginTop: "-1em" }}
        >
          {title}
        </h6>
      )}
      <p className="font-popping text-txtGrey w-11/12 md:w-10/12 mb-4 text-justify">
        {description}
      </p>
    </div>
  );
};

export default TeamMember;
