import React, { useCallback } from "react";
import cn from "classnames";
import {useHistory} from "react-router-dom";
import Text from "../Text";
import ViewAll from "../ViewAll";
import styles from './styles.module.scss';

const BlogCard = ({ blog, className, openInNewTab }) => {
    const { title, description, link, image } = blog || {};
    const history = useHistory();

    const handleClick = useCallback(() => {
        if (openInNewTab) {
            window.open(link, "_blank");
        } else {
            history.push(link);
        }
    }, [link, history, openInNewTab]);

    return (
        <div
            onClick={handleClick}
            className={cn("bg-white bg-no-repeat hover:shadow overflow-hidden rounded-lg flex-1 cursor-pointer", styles.container)}>
            <div className="w-full flex justify-center">
                <img
                    src={image}
                    alt={title}
                    style={{borderRadius: 8, width: "100%"}}
                />
            </div>
            <div className={cn("px-4", styles.blogText)}>
                <h4 className="font-poppins font-semibold text-xl mt-8">{title}</h4>
                <Text className="mt-4 text-justify">{description}</Text>
                <ViewAll className="my-6 invisible" link="#">View details</ViewAll>
            </div>
        </div>
    )
};

export default BlogCard;