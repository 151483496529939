import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import routes from "./routes";
import Home from "./pages/Home";
import Team from "./pages/Team";
import Contact from "./pages/Contact";
import InternshipApply from "./pages/InternshipApply";
import ICPCInternship from "./pages/Initiatives/ICPCInternship";
import CampaignInternship from "./pages/Initiatives/CampaignInternship";
import JobsDatabase from "./pages/Initiatives/JobsDatabase";
import TechInternship from "./pages/Initiatives/TechInternship";
import Workshop from "./pages/Initiatives/Workshop";
import Testimonials from "./pages/Testimonials";
import OurJourney from "./pages/OurJourney"

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path={routes.icpcInternship} component={ICPCInternship} />
        <Route exact path={routes.campaignInternship} component={CampaignInternship} />
        <Route exact path={routes.jobsDatabase} component={JobsDatabase} />
        <Route exact path={routes.techInternship} component={TechInternship} />
        <Route exact path={routes.workshop} component={Workshop} />
        <Route exact path={routes.team} component={Team} />
        <Route exact path="/apply" component={InternshipApply} />
        <Route exact path={routes.contact} component={Contact} />
        <Route exact path={routes.testimonials} component={Testimonials} />
        <Route exact path={routes.ourJourney} component={OurJourney} />
        <Route path={routes.root} component={Home} />
      </Switch>
    </Router>
  );
}

export default App;
