import Header from "../../components/Header";
import Footer from "../../components/Footer";
import React from "react";
import Text from "../../components/Text";

const Contact = () => {
  return (
    <div>
      <Header />
      <div className="bg-white container-fluid">
        <div className="flex flex-wrap items-center w-full px-4 py-16 mx-auto max-w-7xl sm:pt-20 sm:px-6 md:pt-16 lg:py-28 lg:px-8 xl:pt-28">
          <div className="w-full md:w-3/4 sm:w-100">
            <h4 className="mb-6 text-4xl font-semibold text-black font-poppins">
              <span className="block leading-normal xl:inline">
                We'd Love to <span className="text-orange">Hear</span> from You!
              </span>
            </h4>
            <Text className="mb-4">
              Got a question? Send us a message and we will reach out to you as
              soon as possible.
            </Text>
          </div>

          <div className="grid w-full grid-cols-4 gap-6 my-16">
            <div className="col-span-2 md:col-span-1">
              <h6 className="mb-4 font-semibold text-poppins text-subheadingBlack">
                Career Enquiries
              </h6>
              <a href="mailto:opportunities@ppgf.in">
                <Text>opportunities@ppgf.in</Text>
              </a>
            </div>
            {/* <div className="col-span-2 md:col-span-1">
              <p className="mb-4 font-semibold text-poppins text-subheadingBlack">
                General Enquiries
              </p>
              <Text>+91 9029667732</Text>
            </div> */}
            <div className="col-span-2 mt-8 md:col-span-1 md:mt-0">
              <p className="mb-4 font-semibold text-poppins text-subheadingBlack">
                Follow Us On
              </p>
              <div className="flex flex-wrap gap-4">
                <a
                  href="https://twitter.com/PPGFIndia"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="twitter-icon.png" alt="Facebook" />
                </a>
                <a
                  href="https://www.facebook.com/PPGFIndia"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="facebook-icon.png" alt="Facebook" />
                </a>
                <a
                  href="https://www.instagram.com/ppgf_india/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="instagram-icon.png" alt="Facebook" />
                </a>
                <a
                  href="https://www.linkedin.com/company/ppgf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="linkedin-icon.png" alt="Facebook" />
                </a>
              </div>
            </div>
          </div>

          <div className="w-full mt-16">
            <h4 className="text-3xl font-semibold font-poppins text-darkBlue mb-14">
              Find Us At
            </h4>
            <iframe
              title="PPGF Map Location"
              style={{
                filter: "drop-shadow(0px 4px 16px rgba(26, 26, 26, 0.1))",
              }}
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.960042704342!2d77.60891931534319!3d12.974407518325973!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1687393dbc13%3A0x8b70c8e652b73d9e!2sMahatma%20Gandhi%20Rd%2C%20FM%20Cariappa%20Colony%2C%20Sivanchetti%20Gardens%2C%20Bengaluru%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1648555817943!5m2!1sen!2sin"
              width="100%"
              height="580"
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
