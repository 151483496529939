import React, { useState } from "react";
import { Link } from "react-router-dom";
import Text from "../Text";
import routes from "../../routes";

const Header = () => {
  const [mobileMenuOpen, setMobileMenu] = useState(false);
  const [isDropdownOpen, setDropdown] = useState(false);
  const [isAboutUsDropdownOpen, setAboutUsDropdown] = useState(false);
  // const [backgroundColor, setBackgroundColor] = useState("transparent");
  //
  // const handleScrollEvent = e => {
  //   if (window.scrollY > 20) {
  //     setBackgroundColor("#fff");
  //   } else {
  //     setBackgroundColor("transparent");
  //   }
  // };
  //
  // useEffect(() => {
  //   window.addEventListener("scroll", handleScrollEvent);
  //   return () => {
  //     window.removeEventListener("scroll", handleScrollEvent);
  //   };
  // }, []);

  return (
    <div className="relative sticky top-0 z-50 bg-white shadow">
      <div className="mx-auto max-w-7xl sm:px-6">
        <div className="flex items-center justify-between py-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to={routes.root}>
              <span className="sr-only font-poppins">PPGF</span>
              <img
                className="w-auto h-8 ml-2 sm:h-10"
                src="ppgf-header.png"
                alt="PPGF"
              />
            </Link>
          </div>
          <div className="flex mr-2 -my-2 md:hidden">
            <Link
              to={{ pathname: "https://mukherjee.ppgf.in/apply" }}
              target="_blank"
              className="flex items-center justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm font-poppins bg-orange mr-2"
            >
              Mukherjee Fellowship
            </Link>
            <button
              type="button"
              className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={() => setMobileMenu(true)}
            >
              <span className="sr-only">Open menu</span>
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
          <nav className="items-center hidden space-x-10 md:flex">
            <Link
              to={{ pathname: "https://www.vajpayee.ppgf.in/" }}
              target="_blank"
              className="text-base font-medium text-black font-poppins hover:text-gray-500"
            >
              <Text>VPP</Text>
            </Link>
            <Link
              to={{ pathname: "https://mukherjee.ppgf.in/" }}
              target="_blank"
              className="text-base font-medium text-black font-poppins hover:text-gray-500"
            >
              <Text>Mukherjee Fellowship</Text>
            </Link>
            <div className="relative inline-block text-left">
              <div>
                <button
                  type="button"
                  onClick={() => setAboutUsDropdown((val) => !val)}
                  className="inline-flex justify-center font-medium text-black font-poppins"
                  id="menu-button"
                  aria-expanded="true"
                  aria-haspopup="true"
                >
                  <Text>About Us</Text>
                  <svg
                    className="w-5 h-5 mt-1 ml-2 -mr-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
              {isAboutUsDropdownOpen && (
                <div
                  className="absolute right-0 mt-2 transition ease-in-out delay-150 origin-top-right bg-white rounded-md shadow-lg w-60 ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="menu-button"
                  tabIndex="-1"
                >
                  <div className="flex flex-col" role="none">
                    <Link
                      to={routes.ourJourney}
                      className="hover:bg-navItemGrey"
                    >
                      <Text className="p-4 font-medium text-footerBlack hover:text-orange">
                        Journey So Far
                      </Text>
                    </Link>
                    <Link to={routes.team} className="hover:bg-navItemGrey">
                      <Text className="p-4 font-medium text-footerBlack hover:text-orange">
                        Team
                      </Text>
                    </Link>
                    <Link
                      to={routes.testimonials}
                      className="hover:bg-navItemGrey"
                    >
                      <Text className="p-4 font-medium text-footerBlack hover:text-orange">
                        Testimonials
                      </Text>
                    </Link>
                  </div>
                </div>
              )}
            </div>
            <div className="relative inline-block text-left">
              <div>
                <button
                  type="button"
                  onClick={() => setDropdown((val) => !val)}
                  className="inline-flex justify-center font-medium text-black font-poppins"
                  id="menu-button"
                  aria-expanded="true"
                  aria-haspopup="true"
                >
                  <Text>Our Initiatives</Text>
                  <svg
                    className="w-5 h-5 mt-1 ml-2 -mr-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>

              {isDropdownOpen && (
                <div
                  className="absolute right-0 mt-2 transition ease-in-out delay-150 origin-top-right bg-white rounded-md shadow-lg w-60 ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="menu-button"
                  tabIndex="-1"
                >
                  <div className="flex flex-col" role="none">
                    <Link
                      to={routes.icpcInternship}
                      className="hover:bg-navItemGrey"
                    >
                      <Text className="p-4 font-medium text-footerBlack hover:text-orange">
                        ICPC Internship
                      </Text>
                    </Link>
                    <Link
                      to={routes.campaignInternship}
                      className="hover:bg-navItemGrey"
                    >
                      <Text className="p-4 font-medium text-footerBlack hover:text-orange">
                        PPGF Campaign Internship
                      </Text>
                    </Link>
                    <Link
                      to={routes.techInternship}
                      className="hover:bg-navItemGrey"
                    >
                      <Text className="p-4 font-medium text-footerBlack hover:text-orange">
                        Tech Internship
                      </Text>
                    </Link>
                    <Link
                      to={routes.jobsDatabase}
                      className="hover:bg-navItemGrey"
                    >
                      <Text className="p-4 font-medium text-footerBlack hover:text-orange">
                        Jobs Database
                      </Text>
                    </Link>
                    <Link to={routes.workshop} className="hover:bg-navItemGrey">
                      <Text className="p-4 font-medium text-footerBlack hover:text-orange">
                        Workshop
                      </Text>
                    </Link>
                  </div>
                </div>
              )}
            </div>

            {/*<a target="_blank" rel="noreferrer" href="/internship-2021" className="text-base font-medium text-black font-poppins hover:text-gray-500"
            >
              <Text>Blog</Text>
            </a>*/}
            <Link
              to={routes.contact}
              className="text-base font-medium text-black font-poppins hover:text-gray-500"
            >
              <Text>Contact us</Text>
            </Link>
            {/* <Link
              to={{ pathname: "https://mukherjee.ppgf.in/apply" }}
              target="_blank"
              className="inline-flex items-center justify-center px-4 py-2 ml-8 text-base font-medium text-white border border-transparent rounded-md shadow-sm whitespace-nowrap font-poppins bg-orange"
            >
              Apply Now
            </Link> */}
          </nav>
        </div>
      </div>

      {mobileMenuOpen && (
        <div className="absolute inset-x-0 top-0 p-2 transition origin-top-right transform md:hidden">
          <div className="bg-white divide-y-2 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 divide-gray-50">
            <div className="px-5 pt-5 pb-6">
              <div className="flex items-center justify-between">
                <div>
                  <Link to={routes.root}>
                    <img
                      className="w-auto h-8"
                      src="ppgf-header.png"
                      alt="PPGF"
                    />
                  </Link>
                </div>
                <div className="-mr-2">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                    onClick={() => setMobileMenu(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <svg
                      className="w-6 h-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  <Link
                    to={{ pathname: "https://www.vajpayee.ppgf.in/" }}
                    target="_blank"
                    className="pl-2"
                  >
                    <Text className="font-medium text-footerBlack">VPP</Text>
                  </Link>
                  <Link
                    to={{ pathname: "https://mukherjee.ppgf.in/" }}
                    target="_blank"
                    className="pl-2"
                  >
                    <Text className="font-medium text-footerBlack">
                      Mukherjee Fellowship
                    </Text>
                  </Link>
                  <Link to={routes.ourJourney} className="pl-2">
                    <Text className="font-medium text-footerBlack">
                      Journey So Far
                    </Text>
                  </Link>
                  <Link to={routes.team} className="pl-2">
                    <Text className="font-medium text-footerBlack">Team</Text>
                  </Link>
                  <Link to={routes.testimonials} className="pl-2">
                    <Text className="font-medium text-footerBlack">
                      Testimonials
                    </Text>
                  </Link>
                  {/*<a
                    href="blog.ppgf.in"
                    target="_blank"
                    rel="noreferrer"
                    className="pl-2"
                  >
                    <Text className="font-medium text-footerBlack">Blog</Text>
                  </a>*/}
                  <Link to={routes.icpcInternship} className="pl-2">
                    <Text className="font-medium text-footerBlack">
                      ICPC Internship
                    </Text>
                  </Link>
                  <Link to={routes.campaignInternship} className="pl-2">
                    <Text className="font-medium text-footerBlack">
                      PPGF Campaign Internship
                    </Text>
                  </Link>
                  <Link to={routes.techInternship} className="pl-2">
                    <Text className="font-medium text-footerBlack">
                      Tech Internship
                    </Text>
                  </Link>
                  <Link to={routes.jobsDatabase} className="pl-2">
                    <Text className="font-medium text-footerBlack">
                      Jobs Database
                    </Text>
                  </Link>
                  <Link to={routes.workshop} className="pl-2">
                    <Text className="font-medium text-footerBlack">
                      Workshop
                    </Text>
                  </Link>
                  <Link to={routes.contact} className="pl-2">
                    <Text className="font-medium text-footerBlack">
                      Contact us
                    </Text>
                  </Link>
                </nav>
              </div>
            </div>
            {/* <div className="px-5 py-6 space-y-6">
              <div>
                <Link
                  to={{ pathname: "https://mukherjee.ppgf.in/apply" }}
                  target="_blank"
                  className="flex items-center justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm font-poppins bg-orange"
                >
                  Apply Now
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
