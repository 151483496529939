import React, {useCallback} from "react";
import cn from "classnames";
import BlockQuote from "../../../../components/BlockQuote";

const ImageTestimonial = ({ children, className, name, designation, image, link }) => {
    const handleClick = useCallback(() => {
        if (link) {
            window.open(link, "_blank");
        }
    }, [link]);

    return (
        <div className={cn({ [className || '']: className })}>
            <div className="flex flex-col lg:flex-row items-center justify-between">
                <div className="w-8" />
                <div className="w-full pr-10 pt-6 lg:pr-0 lg:w-3/5">
                    <BlockQuote className="pl-12 grow-0 w-100 lg:w-3/4 text-justify" fontScaling>
                        {children}
                    </BlockQuote>

                    <footer className="mt-6 ml-12">
                        <div className={cn("inline-flex items-center", { "cursor-pointer": !!link })} onClick={handleClick}>
                            <div className="flex-shrink-0 inline-flex lg:hidden rounded-full border-2 border-white mr-4">
                                <img
                                    className="h-16 w-16 rounded-full"
                                    src={image}
                                    alt=""
                                />
                            </div>
                            <div>
                                <div className="font-poppins text-footerBlack text-xl font-semibold">{name}</div>
                                <div className="text-txtGrey font-poppins opacity-70 text-sm mt-1">{designation}</div>
                            </div>
                        </div>
                    </footer>
                </div>

                <div className="mt-8 lg:mt-0 w-100 hidden lg:block">
                    <img
                        src={image}
                        alt="Institutional Collaboration with IIDL"
                        style={{ borderRadius: 8, width: 364, maxWidth: "100%" }}
                    />
                </div>

                <div className="w-16" />
            </div>
        </div>
    )
};

export default ImageTestimonial;