export const internFeatured = {
    name: 'Pulkit Bisht',
    designation: 'LLB, OP Jindal Global University',
    link: 'https://linktr.ee/thepulkitsinghbisht',
    image: 'pulkit.png',
    testimonial: 'My internship in the Uttarakhand Campaign enabled by PPGF, was a great learning experience for me, personally as well as professionally. The work environment encouraged me to be creative and innovative and I had the opportunity to learn first hand how data is used to implement better public policies. People here were more than keen to listen to new ideas and helped me improve my half-done plans into implementable versions.'
};

export const internTestimonials = [
    {
        name: 'Vishnu Mangalvedkar',
        designation: 'BA LLB (Hons), Christ University',
        image: 'vishnu.png',
        testimonial: 'The Introductory Course in Political Consulting (ICPC) is like the ‘Akshaya Patra’ or an inexhaustible vessel. It is the perfect opportunity for every student interested in entering the field of political and policy consulting. What I liked most about this course was the opportunity to intern at the offices of Shri BL Santhosh ji, the current National General Secretary (Organization) of the BJP which vastly expanded my understanding of both policy as well as politics. Furthermore, the internship helped me obtain a permanent role in a state election campaign of a national political party upon my graduation.'
    },
    {
        name: 'Harshita J',
        designation: 'BBA, Christ University',
        image: 'harshita.png',
        testimonial: "The entire ICPC internship experience at PPGF has been very enriching for me. While the first module helped me get a clear theoretical understanding of the political structure in the state and country, the second module which was in the office of Shri Rajeev Chandrashekhar (Union MoS for Electronics and Information Technology) gave me a good hands-on experience in policy. This internship also facilitated my full time employment in Mr Rajeev Chandrashekhar's foundation. I thank PPGF for this wonderful opportunity!"
    },
    {
        name: 'Vaibhav Jarera',
        designation: 'B Tech, IIT Delhi',
        image: 'vaibhav-jarera.png',
        testimonial: 'What differentiated the PPGF ICPC internship program for me were the regular sessions with experts where thorough feedback and guidance was provided. This helped me improve my theoretical and practical understanding of politics. The part of my internship wherein I interned directly in the office of the LoP in the Rajasthan Legislative Assembly Shri Gulab Chand Kataria Ji allowed me to interact with learned people in the political space and absorb their experienced wisdom. I’d say that overall it was a wonderful experience and shouldn\'t be missed if you have a keen interest in politics and policy-making!'
    },
    {
        name: 'Pulkit Bisht',
        designation: 'LLB, OP Jindal Global University',
        link: 'https://linktr.ee/thepulkitsinghbisht',
        image: 'pulkit.png',
        testimonial: 'My internship in the Uttarakhand Campaign enabled by PPGF, was a great learning experience for me, personally as well as professionally. The work environment encouraged me to be creative and innovative and I had the opportunity to learn first hand how data is used to implement better public policies. People here were more than keen to listen to new ideas and helped me improve my half-done plans into implementable versions.'
    },
    {
        name: 'Monalisa Nanda',
        designation: 'BA LLB, RGNUL',
        image: 'monalisa.png',
        testimonial: 'The campaign internship enabled by PPGF was my first time interning at a political consultancy. As a student of law and someone who takes keen interest in politics, the research that I was involved in during this internship was extremely intriguing . It exposed me to a much larger, grittier and real life picture of the political arena and I would be lying if I did not admit that this has been the best internship that I have pursued till date'
    },
    {
        name: 'Akhil Krishnan',
        designation: 'BA LLB, DSNLU',
        image: 'akhil-krishnan.png',
        testimonial: 'The ICPC internship has been an insightful experience for me. The assignments in Module 1 were very comprehensive and gave me a good understanding on what tasks one should expect as a political consultant. Apart from the online classes by expert mentors, the offline work also provided me with an on-ground experience of the workings of a politician\'s office- V. Muraleedharan (MoS in the Ministry of Parliamentary Affairs) in my case. It was a great experience that I would highly recommend to those interested in the field.'
    },
    {
        name: 'Subrata Sarmah',
        designation: 'Masters in Conflict Management, BHU',
        image: 'subrata.png',
        testimonial: 'The PPGF Campaign internship was a very rewarding experience for me. Working along with political intelligence and documentation teams, engaging in fieldwork have greatly improved my personal understanding of the political realities of my home state - Assam. Moreover, seeing the party I worked for win in the assembly elections was in itself an immensely satisfying experience.'
    },
    {
        name: 'Ann Tomy',
        designation: 'BBA LLB, Christ University',
        image: 'ann-clara.png',
        testimonial: 'The ICPC course at PPGF has been designed and implemented so meticulously that by the end of it the intern is fully prepared to take on any tasks entrusted to them. For me the client-facing module was an eye-opener as it gave me an opportunity to interact with top politicians and delve into the nitty-gritty of politics. The learnings one gains from these interactions is a vivid instance of witnessing one’s work translating into tangible outcomes.'
    },
    {
        name: 'Shikhar Garg',
        designation: 'BA LLB, NLUJA',
        image: 'shikhar-garg.png',
        testimonial: 'My name is Shikhar Garg, I am a final year law student at NLU Assam and my experience as an intern at PPGF has been absolutely thrilling. To be directly involved in the intricacies of making effective policies through my internship in the office of Shri Rajendra Rathore, the deputy leader of opposition in Rajasthan Legislative Assembly as a part of the ICPC was an amazing learning experience. I would like to thank PPGF for providing me with this opportunity and I am certain that this engagement with PPGF will help me greatly in my career ahead.',
    }
];

export const ministerTestimonials = [
    {
        name: 'General VK Singh',
        designation: 'Minister of State for Civil Aviation and Road Transport and Highways',
        image: 'vk-singh.png',
        testimonial: 'Bhavya joined our office from the PPGF on 2nd October 2021. She put her skills and understanding with respect to policy and politics to good use when it came to the Ministry’s work. Her research and drafting skills assisted the office when it came to preparing briefs, even at a short notice. Throughout the internship, she displayed diligence and professionalism.',
    },
    {
        name: 'Dr.Bharti Pawar',
        designation: 'Minister of State for Health and Family Welfare',
        image: 'bharti-pawar.png',
        testimonial: 'Ms Akanksha Singh has completed her internship with great diligence. I have known Akanksha for two months. She is a fast learner and has the ability to express information and ideas with articulation. I particularly appreciate Ms Akanksha Singh for her spirit and willingness to take on challenging work and deliver.',
    },
    {
        name: 'Shri Keshav Prasad Maurya',
        designation: 'Deputy Chief Minister , Uttar Pradesh',
        image: 'keshav-prasad.png',
        testimonial: 'Shri Rishabh Pandey Ji was assigned to my office by the Policy, Politics and Governance Foundation (PPGF). He worked with us from 10 November 2021 to 25 February 2022. His performance was impeccable in the internship during which he showed dedication and professionalism at all times. His performance during the Uttar Pradesh Assembly Election 2022 was commendable.',
    },
    {
        name: 'Shri V Muraleedharan',
        designation: 'Minister of State for External Affairs and the Minister of State for Parliamentary Affairs',
        image: 'muraleedharan.png',
        testimonial: 'Mr. Akhil H Krishnan worked with us from 24 August 2021 to 29 October 2021. Apart from being able to apply his knowledge, what I admired most about him was his diligence and the quality of his inputs. My experience in working with Akhil was a wholly positive one and his work reflects well on the training provided by PPGF before his assignment here.',
    },
    {
        name: 'Shri Rajendra Rathore',
        designation: 'Deputy Leader of Opposition, Rajasthan',
        image: 'rajendra-rathore.png',
        testimonial: 'Mr. Shikhar Garg, an intern of PPGF, joined my office for a 2 month internship in August 2021. During his time here, he carried out multiple tasks like data collection, speech preparation, research for assembly proceedings and analysis of important political as well as social issues. The sincerity with which he worked was immensely satisfying to me as a legislator.',
    },
]