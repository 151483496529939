import React from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import Text from "../../../components/Text";
import OtherInitiatives from "../components/OtherInitiatives";

export const TechInternship = () => {
    return (
      <div>
        <Header />
        <div className="bg-white container-fluid">
          <div className="flex flex-wrap items-center w-full px-4 py-16 mx-auto max-w-7xl sm:pt-20 sm:px-6 md:pt-16 lg:py-28 lg:px-8 xl:pt-28">
            <div className="w-full md:w-1/2 sm:w-100">
              <h1 className="mb-6 text-4xl font-semibold text-black font-poppins">
                <span className="text-orange">Tech</span> Internship
              </h1>
              <Text className="mb-3 text-justify">
                The PPGF Tech Internship aims to allow students to intern in the
                political campaigns of a national political party in various
                roles relating to election forecasting, survey analysis,
                building data pipelines and automating tasks.
              </Text>
              <Text className="mb-4 text-justify">
                The analytical work and the software development work that
                interns work on in the course of this internship will serve as
                an input to the election campaigns as well as state governments
                of a national political party.
              </Text>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSe-YC7pT4p8nqM_kZNsKWTVE6acYqdFMHYADXc41DJU2PL9gQ/viewform"
                target="_blank"
                rel="noreferrer"
                className="inline-flex items-center justify-center px-4 py-2 mt-8 text-base font-medium text-white border border-transparent rounded-md shadow-sm whitespace-nowrap font-poppins bg-orange"
              >
                Apply to Tech Internship
              </a>
            </div>
            <div className="flex justify-end w-full mt-8 md:w-1/2 md:mt-0">
              <img
                src="tech-internship.png"
                alt="Institutional Collaboration with IIDL"
                style={{ borderRadius: 8, width: "100%", maxWidth: 450 }}
              />
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="flex flex-col flex-wrap items-center justify-center w-full px-4 py-16 mx-auto max-w-7xl sm:pt-20 sm:px-6 md:pt-16 lg:py-28 lg:px-8 xl:pt-28">
            <div className="text-center md:w-1/2 sm:w-full">
              <h4 className="mb-6 text-3xl font-semibold font-poppins">
                <span className="text-orange">Benefits</span> of the Tech
                Internship
              </h4>
            </div>
            <div className="flex flex-col flex-wrap w-full gap-3 my-12 md:flex-row lg:w-2/3">
              <div className="flex-1 px-10 py-6 overflow-hidden text-center bg-white bg-no-repeat rounded-lg shadow-md ">
                <h4 className="mt-5 text-2xl font-medium font-poppins text-orange">
                  #1
                </h4>
                <p className="mt-3 text-darkBlue font-opensans">
                  Work in the analytics and engineering teams of a national
                  political party.
                </p>
              </div>
              <div className="flex-1 px-10 py-6 overflow-hidden text-center bg-white bg-center bg-no-repeat rounded-lg shadow-md ">
                <h4 className="mt-5 text-2xl font-medium font-poppins text-orange">
                  #2
                </h4>
                <p className="mt-3 text-darkBlue font-opensans">
                  Gain an insight into the functioning of statistical models in
                  election forecasting along with the use of big data analytics
                  in campaigns
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="flex flex-col flex-wrap items-center justify-center w-full px-4 py-16 mx-auto max-w-7xl sm:pt-20 sm:px-6 md:pt-16 lg:py-28 lg:px-8 xl:pt-28">
            <div className="text-center md:w-1/2 sm:w-full">
              <h4 className="mb-6 text-3xl font-semibold font-poppins">
                <span className="text-orange">Features</span> of the Tech
                Internship
              </h4>
            </div>
            <div className="flex flex-col flex-wrap w-full gap-3 my-12 md:flex-row">
              <div className="flex-1 px-10 py-6 overflow-hidden text-center bg-white bg-no-repeat rounded-lg shadow-md ">
                <h4 className="mt-5 text-2xl font-medium font-poppins text-orange">
                  #1
                </h4>
                <p className="mt-3 text-darkBlue font-opensans">
                  Interns will be working on live retro model fitting,
                  predicting elections and other elements of survey analytics.
                </p>
              </div>
              <div className="flex-1 px-10 py-6 overflow-hidden text-center bg-white bg-center bg-no-repeat rounded-lg shadow-md ">
                <h4 className="mt-5 text-2xl font-medium font-poppins text-orange">
                  #2
                </h4>
                <p className="mt-3 text-darkBlue font-opensans">
                  Interns can employ their knowledge of statistics, mathematics
                  and data visualization to derive actionable insights that will
                  go a long way in making an impact on a political campaign.
                </p>
              </div>
              <div className="flex-1 px-10 py-6 overflow-hidden text-center bg-white bg-center bg-no-repeat rounded-lg shadow-md ">
                <h4 className="mt-5 text-2xl font-medium font-poppins text-orange">
                  #3
                </h4>
                <p className="mt-3 text-darkBlue font-opensans">
                  Interns will also play a role in building data pipelines that
                  automate the task of collecting, managing, and converting raw
                  data into usable information for analysts to interpret.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-bgGrey container-fluid">
          <div className="flex flex-wrap items-center w-full px-4 py-16 mx-auto max-w-7xl sm:pt-20 sm:px-6 md:pt-16 lg:py-28 lg:px-8 xl:pt-28">
            <div className="w-full md:w-3/4 sm:w-100">
              <h5 className="mb-6 text-2xl font-semibold text-black font-poppins">
                Will I receive a stipend?
              </h5>
              <p className="mb-4 text-justify font-opensans text-footerBlack">
                The interns receive stipends starting from Rs. 5k per month to
                Rs. 25k per month depending on the quality of their work and the
                duration of the internship.
              </p>
            </div>
            <div className="w-full mt-12 md:w-3/4 sm:w-100 sm:mt-16">
              <h5 className="mb-6 text-2xl font-semibold text-black font-poppins">
                What is the minimum duration of the internship?
              </h5>
              <p className="mb-4 text-justify font-opensans text-footerBlack">
                The minimum duration of the campaign internship is one month,
                however the internship can be extended for the duration of the
                campaign at the discretion of the intern and his/her manager.
              </p>
            </div>
            <div className="w-full mt-12 md:w-3/4 sm:w-100 sm:mt-16">
              <h5 className="mb-6 text-2xl font-semibold text-black font-poppins">
                Will I get a certificate?
              </h5>
              <p className="mb-4 text-justify font-opensans text-footerBlack">
                All interns will receive a certificate at the time of completion
                of their internship with the minimum duration of the internship
                required for being eligible for the certificate being 1 month.
              </p>
            </div>
            <div className="w-full mt-12 md:w-3/4 sm:w-100 sm:mt-16">
              <h5 className="mb-6 text-2xl font-semibold text-black font-poppins">
                Who can apply for the internship?
              </h5>
              <p className="mb-4 text-justify font-opensans text-footerBlack">
                PPGF Tech Internship is recommended for all students who are
                interested in the use of analytics and technology to improve
                politics and policy in our country.
              </p>
            </div>
          </div>
        </div>
        <OtherInitiatives />
        <Footer />
      </div>
    );
};

export default TechInternship;