import React from "react";
import cn from "classnames";

const BlockQuote = ({ children, className, fontScaling, ...props }) => (
    <blockquote className={cn("md:flex-grow md:flex md:flex-col", { "md:text-xl xl:text-2xl": fontScaling }, { "text-2xl": !fontScaling }, { [className || '']: className })} {...props}>
        <div className="relative font-poppins text-footerBlack md:flex-grow">
            <svg
                className="absolute top-0 left-0 transform -translate-x-12 -translate-y-5"
                width={60}
                height={50}
                viewBox="0 0 60 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    opacity={0.2}
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22.3086 46.2903C19.8477 48.7634 16.7984 50 13.1605 50C9.30864 50 6.15226 48.7634 3.69136 46.2903C1.23045 43.8172 0 40.6452 0 36.7742C0 34.8387 0.240741 32.957 0.722222 31.129C1.2037 29.3011 2.13992 26.5591 3.53086 22.9032L12.037 0H24.8765L18.4568 24.6774C20.8107 25.6452 22.6564 27.1774 23.9938 29.2742C25.3313 31.371 26 33.871 26 36.7742C26 40.6452 24.7695 43.8172 22.3086 46.2903ZM56.3086 46.2903C53.8477 48.7634 50.7984 50 47.1605 50C43.3086 50 40.1523 48.7634 37.6914 46.2903C35.2305 43.8172 34 40.6452 34 36.7742C34 34.8387 34.2407 32.957 34.7222 31.129C35.2037 29.3011 36.1399 26.5591 37.5309 22.9032L46.037 0H58.8765L52.4568 24.6774C54.8107 25.6452 56.6564 27.1774 57.9938 29.2742C59.3313 31.371 60 33.871 60 36.7742C60 40.6452 58.7695 43.8172 56.3086 46.2903Z"
                    fill="#FF391E"
                />
            </svg>
            <p className="relative">
                {children}
            </p>
        </div>
    </blockquote>
);

export default BlockQuote;