import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Text from "../../components/Text";
import { timelineList } from "./config";
import "./journey.css";

export default function OurJourney() {
  return (
    <div>
      <Header />
      <div className="bg-white container-fluid">
        <div className="flex flex-wrap items-center w-full px-4 py-12 mx-auto max-w-7xl sm:pt-12 sm:px-6 md:pt-16 lg:py-20 lg:px-8 xl:pt-28">
          <div className="w-full">
            <h1 className="w-full mb-10 text-4xl font-semibold text-black font-poppins">
              <span className="text-orange">Our Journey</span>
            </h1>
            <Text className="mb-3 text-justify">
              PPGF was conceptualized by Rangesh Sridhar and Harsh Gupta in
              November 2020 as an institution to create opportunities for
              students and budding professionals with a passion for combining
              policy, politics and governance. In December 2020, the PPGF team
              formulated their first major initiative- Introductory Course in
              Political Consulting (ICPC). To date, 10 batches and more than 200
              students have successfully completed the program.
            </Text>
            <Text className="mb-4 text-justify">
              The first batch of the ICPC Internship began successfully in March
              2021. Soon after, PPGF also launched other robust initiatives,
              such as the PPGF Campaign Internship, PPGF Jobs Database, PPGF
              Tech Internship and PPGF Workshop. Over the past two years, all
              these initiatives have flourished and received a good response
              from stakeholders in the policy and political consulting
              ecosystem.
            </Text>
            <Text className="mb-4 text-justify">
              The newest program of PPGF and its current flagship, is the
              Mukherjee Fellowship which we launched in May 2023 in association
              with the Nation First Policy Research Centre (NFPRC).
            </Text>
          </div>
        </div>
        <div className="w-full">
          <VerticalTimeline lineColor="rgb(255, 56, 30)">
            {timelineList &&
              timelineList.map((timeline, index) => (
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  date={timeline.date}
                  contentStyle={{
                    background: "rgba(255, 255, 255, 1)",
                    borderRadius: "10px",
                    boxShadow:
                      "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
                  }}
                  contentArrowStyle={{
                    borderRight: "7px solid  rgb(255, 56, 30)",
                  }}
                  iconStyle={{
                    background: "rgb(228 228 228)",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  icon={
                    <img
                      src={timeline.icon}
                      alt={timeline.title}
                      style={{ borderRadius: 8, width: "40px" }}
                    />
                  }
                  style={{ boxShadow: "none" }}
                >
                  <h1
                    className={`w-full text-xl font-semibold text-black font-poppins ${
                      index % 2 === 0 && "text-right-desktop"
                    }`}
                  >
                    {timeline.title}
                  </h1>
                </VerticalTimelineElement>
              ))}
          </VerticalTimeline>
        </div>
      </div>
      <Footer />
    </div>
  );
}
