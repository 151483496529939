import React from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import Text from "../../../components/Text";
// import BlockQuote from "../../../components/BlockQuote";
import OtherInitiatives from "../components/OtherInitiatives";

export const CampaignInternship = () => {
    return (
      <div>
        <Header />
        <div className="bg-white container-fluid">
          <div className="flex flex-wrap items-center w-full px-4 py-16 mx-auto max-w-7xl sm:pt-20 sm:px-6 md:pt-16 lg:py-28 lg:px-8 xl:pt-28">
            <div className="w-full md:w-1/2 sm:w-100">
              <h1 className="mb-6 text-4xl font-semibold text-black font-poppins">
                PPGF <span className="text-orange">Campaign</span> Internship
              </h1>
              <Text className="mb-3 text-justify">
                The PPGF Campaign Internship Program facilitates internships in
                the political campaigns of a prominent national party across
                election going states in India where interns get a real-time
                glimpse of election campaigning. Within less than one year
                itself, nearly 300 interns have gotten the opportunity to work
                in  different election campaigns for states including
                Karnataka, Tamil Nadu, Assam, Punjab, Uttarakhand, Himachal Pradesh and Goa.
              </Text>
              <Text className="mb-4 text-justify">
                The research, analysis and recommendations that interns work on
                in the course of this internship will serve as an input to the
                election campaigns as well as state governments of a national
                political party.
              </Text>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSek3cHFJZWUyzUNcccj_8FXjozsjSZ3s6YfhTI1KjmB97ILdw/viewform"
                target="_blank"
                rel="noreferrer"
                className="inline-flex items-center justify-center px-4 py-2 mt-8 text-base font-medium text-white border border-transparent rounded-md shadow-sm whitespace-nowrap font-poppins bg-orange"
              >
                Apply to PPGF Campaign Internship
              </a>
            </div>
            <div className="flex justify-end w-full mt-8 md:w-1/2 md:mt-0">
              <img
                src="campaign-group.png"
                alt="Institutional Collaboration with IIDL"
                style={{ borderRadius: 8, width: "100%", maxWidth: 450 }}
              />
            </div>
          </div>
        </div>

        {/*<div className="container-fluid">
                <div className="flex flex-col flex-wrap items-center justify-center w-full px-4 py-16 pl-16 mx-auto max-w-7xl sm:pt-20 sm:px-6 md:pl-16 md:pt-16 lg:py-28 lg:px-8 xl:pt-28">
                    <div className="flex flex-col flex-wrap w-full mb-12 md:flex-row lg:w-2/3">
                        <BlockQuote>
                            PPGF Campaign Internship is recommended for all students who are interested in&nbsp;
                            <span className="text-orange">exploring the space of political consulting and policy research</span> but aren’t sure of
                            entering it yet.
                        </BlockQuote>
                    </div>
                </div>
            </div>*/}

        <div className="container-fluid">
          <div className="flex flex-col flex-wrap items-center justify-center w-full px-4 py-16 mx-auto max-w-7xl sm:pt-20 sm:px-6 md:pt-16 lg:py-28 lg:px-8 xl:pt-28">
            <div className="text-center md:w-1/2 sm:w-full">
              <h4 className="mb-6 text-3xl font-semibold font-poppins">
                <span className="text-orange">Benefits</span> of the Campaign
                Internship
              </h4>
            </div>
            <div className="flex flex-col flex-wrap w-full gap-3 my-12 md:flex-row">
              <div className="flex-1 px-10 py-6 overflow-hidden text-center bg-white bg-no-repeat rounded-lg shadow-md ">
                <h4 className="mt-5 text-2xl font-medium font-poppins text-orange">
                  #1
                </h4>
                <p className="mt-3 text-darkBlue font-opensans">
                  <strong>Gain an insight</strong> into both the policy making
                  space and the political consultancy space.
                </p>
              </div>
              <div className="flex-1 px-10 py-6 overflow-hidden text-center bg-white bg-center bg-no-repeat rounded-lg shadow-md ">
                <h4 className="mt-5 text-2xl font-medium font-poppins text-orange">
                  #2
                </h4>
                <p className="mt-3 text-darkBlue font-opensans">
                  <strong>Gain practical experience</strong> in policy making
                  process, legal analysis and political analysis.
                </p>
              </div>
              <div className="flex-1 px-10 py-6 overflow-hidden text-center bg-white bg-center bg-no-repeat rounded-lg shadow-md ">
                <h4 className="mt-5 text-2xl font-medium font-poppins text-orange">
                  #3
                </h4>
                <p className="mt-3 text-darkBlue font-opensans">
                  Work with and under the{" "}
                  <strong>mentorship of senior senior political leaders</strong>{" "}
                  including party leaders, consultants and ministers.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-bgGrey container-fluid">
          <div className="flex flex-wrap items-center w-full px-4 py-16 mx-auto max-w-7xl sm:pt-20 sm:px-6 md:pt-16 lg:py-28 lg:px-8 xl:pt-28">
            <div className="w-full md:w-3/4 sm:w-100">
              <h5 className="mb-6 text-2xl font-semibold text-darkBlue font-poppins">
                What will my responsibilities as an intern be?
              </h5>
              <Text className="mb-4 text-justify text-footerBlack">
                In state election campaigns, interns take on multiple
                responsibilities such as - writing policy briefs, being a part
                of the manifesto creation process and in analyzing the political
                situation in various constituencies.
              </Text>
            </div>
            <div className="w-full mt-12 md:w-3/4 sm:w-100 sm:mt-16">
              <h5 className="mb-6 text-2xl font-semibold text-black font-poppins">
                Is this a stipend-based internship?
              </h5>
              <p className="mb-4 text-justify font-opensans text-footerBlack">
                During the tenure, interns receive stipends ranging between Rs.
                5k per month to Rs. 25k per month. The amount depends on the
                quality of work produced and the duration of the internship.
              </p>
            </div>
            <div className="w-full mt-12 md:w-3/4 sm:w-100 sm:mt-16">
              <h5 className="mb-6 text-2xl font-semibold text-black font-poppins">
                What is the minimum duration of the internship?
              </h5>
              <p className="mb-4 text-justify font-opensans text-footerBlack">
                The minimum duration of the campaign internship is one month,
                however the internship can be extended for the duration of the
                campaign at the discretion of the intern and his/her manager.
              </p>
            </div>
            <div className="w-full mt-12 md:w-3/4 sm:w-100 sm:mt-16">
              <h5 className="mb-6 text-2xl font-semibold text-black font-poppins">
                Will I get a certificate?
              </h5>
              <p className="mb-4 text-justify font-opensans text-footerBlack">
                All interns will receive a certificate at the time of completion
                of their internship with the minimum duration of the internship
                required for being eligible for the certificate being 1 month.
              </p>
            </div>
            <div className="w-full mt-12 md:w-3/4 sm:w-100 sm:mt-16">
              <h5 className="mb-6 text-2xl font-semibold text-black font-poppins">
                Who can apply for the internship?
              </h5>
              <p className="mb-4 text-justify font-opensans text-footerBlack">
                PPGF Campaign Internship is recommended for all students who are
                interested in exploring the space of political consulting and
                policy research but aren’t sure of entering it yet.
              </p>
            </div>
          </div>
        </div>

        <OtherInitiatives />
        <Footer />
      </div>
    );
};

export default CampaignInternship;