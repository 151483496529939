import React from "react";
import cn from "classnames";
import styles from './styles.module.scss';
import {Link} from "react-router-dom";

const ViewAll = ({ children, link, className, ...props }) => {
    return (
        <Link to={link}
           className={cn("text-orange font-poppins font-medium flex items-center", styles.link, {[className || '']: className})} {...props}>{children}
            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                 className="ml-3">
                <path d="M1 8L16 8" stroke="#FF391E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M9 15L16 8L9 0.999999" stroke="#FF391E" strokeWidth="2" strokeLinecap="round"
                      strokeLinejoin="round"/>
            </svg>
        </Link>
    )
};

export default ViewAll;